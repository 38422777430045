import styled from "styled-components";
import { VideoTile } from "./TrainingList";
import { Col, Row } from "react-bootstrap";
import eyeglass from '../../assets/images/glyph-search-gray.svg';
import crossArrow from '../../assets/images/cross-arrow.svg';

import { Input } from "../../components";

export const CustomCard = styled.div`
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border: 1px solid  #b9b7b7!important;
    border-radius: 8px;
    padding: 2rem;
`;

export const GroupTrainingContainer = styled(CustomCard)`
    margin: 6.5rem 0.5rem 0rem 6.5rem !important;
    width: 100%;
    align-self: flex-start;
    align-items: flex-start;
   
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

export const HeaderContainer = styled.div`
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
`;

export const CreditContainer = styled.div`
    display:flex;
    justify-content: space-between;
    border-top: 1px solid #42A764;
    border-bottom: 1px solid #42A764;
`;

export const HeaderText = styled.h2`
    font-weight: bold;
    font-family:"Haus Neue";
`;

export const SubHeader = styled.p`
    font-weight: 600;
    font-size: 18px;
    font-family: "Haus Neue";
    padding: 0px;
    margin-bottom: 0px;
`;


export const NavigationTabSection = styled(Row)`
    color:#676767 !important;
    margin-bottom: 1rem!important;
    margin-right: 0px !important; 
    margin-left: 0px !important;
    line-height: 30px;
`;

export const NavTab = styled(Col)`
    font-size: 1.2rem;
    font-weight: 600 !important;
    text-align: center;
    cursor:pointer;
    border-bottom: 1px solid #676767;
    @media screen and (max-device-width: 1024px) and (orientation: portrait) {
        line-height: 3rem;
        white-space: pre;
    }
    @media screen and (max-device-width:1024px) and (orientation: landscape) {
        line-height: 3rem;
        white-space: pre;
    }
    ${(props) => !props.disable &&
        ':hover{ background: #e3ffec !important;}'
    }
    
    &.active {
        color:#42A764 !important;
        border-bottom: 2px solid #42A764;
    }
`;

export const NewVideoTile = styled(VideoTile)`
    border: none;
    box-shadow: none;
`;

export const VideoContainer = styled.div`
    border: ${(props) => (props.selected ? '1px #7afb7a solid' : 'none')};
    box-shadow: ${(props) => (props.selected ? '0 1px 4px 0 #87b187' : '0 1px 4px 0 #898989')};
    border-radius: 8px;
    margin: 1rem; 
`;

export const SelectedIndex = styled.div`
    position: absolute;
    background: #F3F9F5;
    width: 25px;
    height: 25px;
    border-radius: 28px;
    right: 25px;
    top: 18px;
    color: #4FB973;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    .not_disabled{
            cursor: pointer !important;

    }
`;

export const TableContainer = styled.div`
    overflow-y:auto;
    min-height: 30vh;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '61vh')};
`;

export const TableHead = styled.thead`
    background: white;
    top: 0;
    position: sticky;
    th {
          vertical-align: middle !important;
    }
`;

export const SearchInput = styled(Input)`
  background: url('${eyeglass}') white;
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 30px; /* Adjust the padding as needed */
  width: 100%;
  
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
      width:70%;
  }
`;

export const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;


export const CrosseArrowButton = styled.div`
  color: var(--key-color);
  font-weight: 600;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    width: calc(0.9em - 1px);
    height: calc(0.8em);
    margin-left: 5px;
    background-image: url("${crossArrow}");
    background-size: cover;
    background-repeat: no-repeat;
    transition: margin-left 0.25s ease-out;
  }
  &:hover {
    color: var(--key-color);
    &::after {
      margin-left: 10px; 
    }
  }
  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`;
