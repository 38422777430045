import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Card, Figure, Row, Col, Alert, Spinner } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

import clinical from '../../assets/roles-icon/clinical.svg';
import nonClinical from '../../assets/roles-icon/nonClinical.svg';
import provider from '../../assets/roles-icon/provider.svg';
import office from '../../assets/roles-icon/office.svg';
import support from '../../assets/roles-icon/support.svg';
import customRoleIcon from '../../assets/roles-icon/custom-role.svg';
import pencil from '../../assets/images/white_pencil.svg';

import styled from 'styled-components';
import { AddEditSafetyEquipment, Button } from '../../components';
import { WhiteButton } from './Style';

const ContentHeader = styled.h5`
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin: 0 1.5rem 1rem;
    color:#676767;
    padding-bottom: 1rem;

    @media screen and (max-device-width:450px) and (orientation: portrait) {
    margin-left: 1.5rem;
    }
    @media screen and (max-device-width:450px) and (orientation: landscape) {
    margin-left: 1.5rem;
    }
`;

const SafetyEquipmentContainer = styled.div``;

const FormHeader = styled.div`
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const CustomCard = styled(Card)`
    cursor: pointer;
    border:1px solid #EFEFEF !important;
    border-radius:7px !important;
    margin-bottom: 0.5rem!important;
`;

const CardBody = styled(Card.Body)`
:hover {
    background: #4FB973;
    transition: all 0.2s ease-out;

    .text-success {
    color: #fff !important;
    }

    .figure-caption {
    color: #fff !important;
    }
}
.figure-caption {
    font-size:1rem;
}
`;

const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;

const NameBlock = styled.span`
    padding:8px 8px
    border-radius:50%;
    background-color:#E7F3EB;
    color:#4FB973;
    margin-right: 1rem;
    font-size: 0.875em
    text-transform: uppercase;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
`;

const StyledCard = styled.div`
    background:#fff;
    border:1px solid #EFEFEF;
    padding:10px;
    box-shadow:none;
    border-radius:10px;
    color:#000 !important; 
    margin-bottom: 1rem!important;
    text-align: left;
    display: flex;
    width:100%;
    align-items: center;
    img{
        margin-right:1rem;
    }
    h6{
        @media screen and (max-device-width:450px) and (orientation: portrait) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
        @media screen and (max-device-width:450px) and (orientation: landscape) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
    }
`;

const DetailContainer = styled.div`
    overflow-y: auto;
    max-height:60vh;
`;

const NameBlockElement = ({ firstName, lastName }) => {
    return (
        <NameBlock>
            {firstName[0] + lastName[0]}
        </NameBlock>
    );
};

class SafetyEquipmentView extends Component {
    state = {
        loading: false,
        safetyEquipmentDetails: { name: '', _id: '' },
        members: [],
        roles: [],
        errors: {},
        addOrEditSafetyEquipment: false
    };

    componentDidMount() {
        this.getSafetyEquipment();
    };

    getSafetyEquipment = async () => {
        try {
            this.setState({ loading: true });
            const { history: { location } } = this.props;
            const searchParams = new URLSearchParams(location.search);
            const safetyEquipmentId = searchParams.get('safetyEquipmentId');
            const { data: { payload } } = await axios.get(`/api/v2/safetyEquipment/${safetyEquipmentId}`);
            await this.getMasterData(payload);
            this.setState({ safetyEquipmentDetails: payload, loading: false });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false, errors: { apiError: true, } });
            return {};
        }
    };

    getMasterData = async (safetyEquipment) => {
        try {
            const { data: { payload: members } } = await axios.get(`/api/v2/master/users_by_equipment/${safetyEquipment._id}`);
            const { data: { payload: roles } } = await axios.get(`/api/v2/master/roles_by_equipment/${safetyEquipment._id}`);
            this.setState({ members, roles, loading: false });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Error at getting safetyEquipment.' } });
            console.error(error);
        }
    };

    closePopUpModal = async () => {
        this.setState({ addOrEditSafetyEquipment: false });
        await this.getSafetyEquipment();
    };

    render() {
        const { history } = this.props;
        const { loading, safetyEquipmentDetails, safetyEquipmentDetails: { name }, members, roles, addOrEditSafetyEquipment, } = this.state;

        const icons = { clinical, support, nonClinical, provider, office };

        return (
            loading ?
                <div style={{ minHeight: '30px' }} className='w-100 text-center'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <SafetyEquipmentContainer>
                    <div className="d-flex justify-content-between mx-2">
                        <FormHeader>
                            <h2>{name}</h2>
                        </FormHeader>
                        <div className='d-flex justify-content-between'>
                            <Button onClick={() => { this.setState({ addOrEditSafetyEquipment: true }) }} width='250' className='my-0 mr-2'>  <img style={{ cursor: 'pointer' }} src={pencil} className='mr-2' alt="Edit" />Edit</Button>
                            <WhiteButton onClick={() => { history.goBack() }} width='250' className='my-0'>{'<< Back'}</WhiteButton>
                        </div>
                    </div>
                    <DetailContainer>
                        <Row className="mx-2">
                            <ContentHeader>Members Assigned</ContentHeader>
                            {(members && members.length) ? members.map(({ firstName, lastName, _id, fullName }) => (
                                <Col xs={12} md={4} lg={3} key={_id}>
                                    <StyledCard>
                                        <NameBlockElement firstName={firstName} lastName={lastName} /> <h6>{fullName}</h6>
                                        <Tooltip placement="bottom" className="in" id="tooltip-bottom">
                                            {fullName}
                                        </Tooltip>
                                    </StyledCard>
                                </Col>
                            )) :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>This Safety Equipment has not been assigned to any member.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                        <Row className="justify-content-start mx-2">
                            <ContentHeader>Roles</ContentHeader>
                            {(roles && roles.length) ? roles.map((ROLE) => (
                                <Col xs={12} md={4} lg={4} key={ROLE._id} >
                                    <CustomCard>
                                        <CardBody>
                                            <IconContainer className="justify-content-start align-items-center">
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={icons[ROLE.defaultID] || customRoleIcon}
                                                />
                                                <Figure.Caption>
                                                    {ROLE.name}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            )) :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>This item does not included as a default item for any roles.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                    </DetailContainer>
                    {
                        addOrEditSafetyEquipment && <AddEditSafetyEquipment addOrEditSafetyEquipment={addOrEditSafetyEquipment} safetyEquipment={safetyEquipmentDetails} closePopUpModal={this.closePopUpModal} user={this.props.user} />
                    }
                </SafetyEquipmentContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(SafetyEquipmentView);