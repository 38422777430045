import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NavBar, Header } from '.';
import { OSHA_Advertise } from '../pages';

export const AuthRouteOSHA = ({ component: Component, authenticated, user, path, history, ...rest }) => {
    let isValidSub = false
    if (user && user.location && user.location.locSubscriptionPlan && ['OSHA', 'OSHA&SDS'].includes(user.location.locSubscriptionPlan)) isValidSub = true;
    return (<Route
        {...rest}
        render={(props) =>
            (Cookies.get('token') && user.isRegistrationCompleted === 2) ? (
                !user.isSubscriptionFailed ?
                    <React.Fragment>
                        <NavBar {...props} />
                        <Header {...props} />
                        {isValidSub ? <Component {...props} /> : <OSHA_Advertise />}
                    </React.Fragment>
                    :
                    <Redirect to='/update_subscription' />)
                :
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location } // Save current URL in state
                    }}
                />
        }
    />)
}
