import axios from 'axios';
import { useEffect, useState } from 'react';
import { Alert, ButtonGroup, Col, Container, Modal, Row, Spinner, ToggleButton, Button as BsButton } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { Button } from '../Button';
import { Input } from '../Input';
import { Select } from '../Select';
import { states } from '../../helpers';
import CardElement from '../Stripe/CardElement'
import { Chip } from '../../pages/Registration/Style';
import { DateRangeInputContainer } from '../DateInput';
import UserListWithAnnualDate from './UserListWithAnnualData';
import moment from 'moment';

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;

const SubHeaderSection = styled.div`
    display: flex;
    justify-content:space-between;
    padding:0px !important;
    margin-left:15px !important;
    margin-right:15px !important;
    border-bottom: 1px solid #4FB973;
`;

const SubHeader = styled.h6`
    color:#4FB973;
    margin:0px !important;
    font-weight: 600 !important;
`;

const Steps = styled.p`
    margin:0px !important;
`;

const InsideCreditCardDiv = styled.div`
align-items: center;
padding: 10px 15px;
box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
border-radius: 7px;
background: #F3F9F5;
width: 100% !important;
display: flex;
justify-content: space-between;
`;

const AddEditLocation = ({ location, closePopUpModal, show, user, afterLocationUpdateOrAdd }) => {

    const [locationDetails, setLocationDetails] = useState({ name: '', type: [], options: [], notSelectedOptions: [], address: { address: '', address2: '', city: '', state: '', zip: '' }, userRole: '', practice: user.practice._id, locSubscriptionPlan: user.location.locSubscriptionPlan });
    const [loading, setLoading] = useState(false);
    const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
    const [error, setError] = useState({ apiError: false });
    const [stripe, setStripe] = useState({});
    const [defaultRoles, setDefaultRoles] = useState([]);
    const [defaultLocationTypes, setDefaultLocationTypes] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [steps, setSteps] = useState({ currentStep: 0, totalSteps: 0, title: '' });
    const [planDetails, setPlanDetails] = useState({});

    const [couponCode, setCouponCode] = useState('');
    const [appliedCoupon, setAppliedCoupon] = useState('');
    const [couponId, setCouponId] = useState('');
    const [priceDetails, setPriceDetails] = useState({ basePrice: 0, discountPrice: 0, remaining: 0 });
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        if (!location) {
            setSteps({ currentStep: 1, totalSteps: 2, title: 'Location Details' });
        } else {
            setSteps({ currentStep: 1, totalSteps: 1, title: 'Location Details' });
        };
        getMasterData();
    }, []);

    const getLocation = async (options) => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/location/' + location);
            const notSelectedOptions = [];
            !payload.setUpPending && options.length && options.forEach((option) => {
                if (!payload.options.includes(option.value)) {
                    notSelectedOptions.push(option.value);
                };
            });
            setLocationDetails({ ...payload, notSelectedOptions });
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getLocation ~ error:", error)
            setError({ apiError: true });
            setLoading(false);
        }
    };

    const getMasterData = async () => {
        try {
            setLoading(true);
            const { data: { payload: stripeData } } = await axios.get('/api/v2/practice/billing_details/' + user.practice.billingId);
            const { data: { payload: roles } } = await axios.get('/api/v2/master/defaultroles');
            const { data: { payload: defaultlocationtypes } } = await axios.get('/api/v2/master/defaultlocationtypes');
            const { data: { payload: defaultoptions } } = await axios.get('/api/v2/master/defaultoptions');
            if (!location) await getPlan();
            setStripe(stripeData);
            setDefaultRoles(roles);
            setDefaultLocationTypes(defaultlocationtypes);
            setDefaultOptions(defaultoptions);
            if (location) {
                getLocation(defaultoptions);
            } else {
                setLoading(false);
            };
        } catch (error) {
            setDefaultRoles([]);
            setDefaultLocationTypes([]);
            setDefaultOptions([]);
            setError({ apiError: true });
            setLoading(false);
        }
    };

    const onModelChange = (name, value) => {
        const temp = { ...locationDetails };
        if (name === 'type') {
            temp.type = [value];
        } else if (['address', 'address2', 'state', 'city', 'zip'].includes(name)) {
            temp.address[name] = value;
        } else if (name === 'locSubscriptionPlan') {
            temp[name] = value;
            removeCoupon();
            calculatePrice(planDetails[value], 0, 0);
        } else if (name === 'annual_review_date') {
            temp[name] = moment(value).format('DD/MM');
            setSteps({ currentStep: 1, totalSteps: 2, title: 'Location Details' });
        } else {
            temp[name] = value;
        };
        setLocationDetails(temp);
    };

    const onOptionChange = ({ name, value }) => {
        const { notSelectedOptions, options } = locationDetails;
        if (name === 'onSiteLab') {
            if (value === 'no') {
                const labIsCliaWaived = options.indexOf('labIsCliaWaived');
                if (labIsCliaWaived !== -1) {
                    options.splice(labIsCliaWaived, 1);
                };
                document.getElementById('labIsCliaWaived').classList.add('d-none');
                if (!notSelectedOptions.includes('labIsCliaWaived')) notSelectedOptions.push('labIsCliaWaived');
            } else {
                document.getElementById('labIsCliaWaived').classList.remove('d-none');
            };
        };

        if (name === 'onSiteTbTesting') {
            if (value === 'no') {
                const onSiteTbTreatment = options.indexOf('onSiteTbTreatment');
                if (onSiteTbTreatment !== -1) {
                    options.splice(onSiteTbTreatment, 1);
                };
                document.getElementById('onSiteTbTreatment').classList.add('d-none');
                if (!notSelectedOptions.includes('onSiteTbTreatment')) notSelectedOptions.push('onSiteTbTreatment');
            } else {
                document.getElementById('onSiteTbTreatment').classList.remove('d-none');
            };
        };

        const index1 = notSelectedOptions.indexOf(name);
        if (index1 !== -1) {
            notSelectedOptions.splice(index1, 1);
        };
        const index = options.indexOf(name);
        if (index !== -1) {
            options.splice(index, 1);
        };
        if (value === 'no') {
            notSelectedOptions.push(name);
        } else {
            options.push(name);
        };
        setLocationDetails({ ...locationDetails, options, notSelectedOptions });
    };

    const validate = () => {
        let error = false;
        setError({ apiError: false });
        const { name, options, notSelectedOptions, type, userRole, address: { address, city, state, zip }, _id, annual_review_date } = locationDetails;
        if (!name.trim() || !type[0] || !address.trim() || !city.trim() || !state.trim() || !zip.trim()) {
            error = true;
            setError({ apiError: false, dataError: true, errorMsg: 'Fill all required fields.' });
            return error;
        };
        if (location && [...options, ...notSelectedOptions].length !== defaultOptions.length) {
            error = true;
            setError({ apiError: false, dataError: true, errorMsg: 'Fill all required fields.' });
            return error;
        };
        if (zip.length !== 5) {
            error = true;
            setError({ apiError: false, dataError: true, errorMsg: 'Invalid Zip code.' });
            return error;
        };
        if (!annual_review_date) {
            error = true;
            setError({ apiError: false, dataError: true, errorMsg: 'Select annual review date' });
            return error;
        };
        if (!_id && !userRole) {
            error = true;
            setError({ apiError: false, dataError: true, errorMsg: 'Select your role for this location.' });
            return error;
        };
    };

    const saveOrUpdate = async () => {
        try {
            setSaveOrUpdateInProgress(true);
            const error = validate();
            if (!error) {
                if (steps.currentStep !== steps.totalSteps) {
                    if (location && locationDetails.annual_review_date != locationDetails.old_review_date) {
                        setSteps({ currentStep: 2, totalSteps: 2, title: 'Annual Review Date Details' });
                    } else {
                        setSteps({ currentStep: 2, totalSteps: 2, title: 'Billing Details' });
                    };
                    setSaveOrUpdateInProgress(false);
                    return;
                };
                if (!locationDetails._id) {
                    const dataToSave = { couponId, locationData: locationDetails, selectedUsers, userData: { _id: user._id, userRole: locationDetails.userRole } };
                    await axios.post('/api/v2/location', dataToSave);
                    await afterLocationUpdateOrAdd();
                    setSaveOrUpdateInProgress(false);
                    closePopUpModal(true);
                    Swal.fire({
                        icon: 'success',
                        title: 'Location has been added successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                } else {
                    await axios.put(`/api/v2/location/${locationDetails._id}`, { locationDetails, selectedUsers });
                    await afterLocationUpdateOrAdd();
                    setSaveOrUpdateInProgress(false);
                    closePopUpModal(true);
                    Swal.fire({
                        icon: 'success',
                        title: 'Location has been updated successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                };
            } else {
                setSaveOrUpdateInProgress(false);
            }
        } catch (error) {
            console.error(error);
            if (error.response?.status === 400) {
                setError({ apiError: true, errorMsg: error.response.data.error.message });
            } else {
                setError({ apiError: true });
            };
            setSaveOrUpdateInProgress(false);
        }
    };

    const checkOptionAndHideOrShow = (id) => {
        const { options } = locationDetails;
        let result = false;
        if (['labIsCliaWaived', 'onSiteTbTreatment'].includes(id)) {
            if (!location) {
                return true;
            } else {
                if ('labIsCliaWaived' === id && !options.includes('onSiteLab')) result = true;
                if ('onSiteTbTreatment' === id && !options.includes('onSiteTbTesting')) result = true;
            }
        };
        return result;
    };

    const getPlan = async () => {
        try {
            const { data } = await axios.get('/api/v2/planDetails');
            setPlanDetails(data);
            calculatePrice(data[locationDetails.locSubscriptionPlan]);
        } catch (error) {
            console.error('error========', error);
        }
    };

    const calculatePrice = (plan, discount, amountOff) => {
        const basePrice = plan.tiers[1].unit_amount;
        const discountPrice = (discount * basePrice) / 100;
        const remaining = (basePrice - discountPrice) - amountOff;
        setPriceDetails({ basePrice, discountPrice, remaining });
    };

    const removeCoupon = () => {
        calculatePrice(planDetails[locationDetails.locSubscriptionPlan], 0, 0);
        setCouponCode('');
        setCouponId('');
        setAppliedCoupon('');
    };

    const applyCoupon = async () => {
        try {
            if (locationDetails.locSubscriptionPlan) {
                const { data } = await axios.post(`/api/v2/checkCoupon`, { couponCode, selectedPlan: locationDetails.locSubscriptionPlan, amount: priceDetails.basePrice });
                if (data && data.valid) {
                    setAppliedCoupon(couponCode);
                    setCouponId(data.id);
                    const discount = data ? data.percent_off : 0;
                    const amountOff = data.amount_off ? data.amount_off : 0;
                    calculatePrice(planDetails[locationDetails.locSubscriptionPlan], discount, amountOff);
                } else if (data && !data.valid) {
                    removeCoupon();
                    toast.error('This coupon is not valid');
                };
            } else {
                removeCoupon();
                toast.error('Select billing cycle first.');
            };
        } catch (error) {
            console.log("🚀 ~ applyCoupon ~ error:---->>>", error);
            removeCoupon();
            toast.error('This coupon is not valid');
        }
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg' show={show}>
            <Modal.Header>
                <Modal.Title>{location ? 'Edit' : 'Add new'} location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {loading ?
                        <Container className='text-center my-3'>
                            <Spinner animation="border" variant="success" />
                        </Container> :
                        <>
                            <SubHeaderSection>
                                <SubHeader>{steps.title}</SubHeader>
                                <Steps>{steps.currentStep} / {steps.totalSteps}</Steps>
                            </SubHeaderSection>
                            {steps.currentStep === 1 &&
                                <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                    <Row>
                                        <Col>
                                            <Input required={true} onChange={(e) => onModelChange('name', e.target.value)} label='Name' type='text' placeholder='Name' value={locationDetails.name} />
                                        </Col>
                                        <Col>
                                            <StyledLabel>Location Type<span className="text-danger">*</span></StyledLabel>
                                            <Select defaultValue={defaultLocationTypes.find((type) => type.value === locationDetails.type[0])} handleDropdownChange={(e) => onModelChange('type', e.target.value)} options={defaultLocationTypes} placeholder='Location Type' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Input required={true} onChange={(e) => onModelChange('address', e.target.value)} label='Street Address' type='text' placeholder='Address' value={locationDetails.address.address || ''} />
                                        </Col>
                                        <Col>
                                            <Input onChange={(e) => onModelChange('address2', e.target.value)} label='&nbsp;' type='text' placeholder='Address Line 2' value={locationDetails.address.address2} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Input required={true} onChange={(e) => onModelChange('city', e.target.value)} label='City' type='text' placeholder='City' value={locationDetails.address.city} />
                                        </Col>
                                        <Col>
                                            <Input numberOnly={true} required={true} onChange={(e) => onModelChange('zip', e.target.value)} label='Zip Code' type='text' placeholder='Zip Code' value={locationDetails.address.zip} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='12' lg='6' md='6'>
                                            <StyledLabel>State<span className="text-danger">*</span></StyledLabel>
                                            <Select defaultValue={states.find((state) => state.value === locationDetails.address.state)} handleDropdownChange={(e) => onModelChange('state', e.target.value)} options={states} placeholder='State' />
                                        </Col>
                                        {!location &&
                                            <Col sm='12' lg='6' md='6'>
                                                <StyledLabel>Your Role<span className="text-danger">*</span></StyledLabel>
                                                <Select handleDropdownChange={(e) => onModelChange('userRole', e.target.value)} options={defaultRoles} placeholder='Your Role' />
                                            </Col>
                                        }
                                        <Col sm='12' lg='6' md='6'>
                                            <StyledLabel>Annual Review Date(dd/mm)<span className="text-danger">*</span></StyledLabel>
                                            <DateRangeInputContainer className='p-0'>
                                                <DatePicker
                                                    value={locationDetails.annual_review_date}
                                                    placeholderText='dd/mm'
                                                    popperPlacement='bottom-end'
                                                    excludeScrollbar={false}
                                                    fixedHeight={true}
                                                    dateFormat="dd/MM"
                                                    onChange={(e) => onModelChange('annual_review_date', e)}
                                                    className="date-input"
                                                    isClearable={false}
                                                />
                                            </DateRangeInputContainer>
                                        </Col>
                                    </Row>
                                    {location && defaultOptions.length && defaultOptions.map((option) => (
                                        <Row id={option.value} className={'mt-2 ' + (checkOptionAndHideOrShow(option.value) ? 'd-none' : '')} key={option.value}>
                                            <Col>
                                                <StyledLabel>{option.label} <span className="text-danger">*</span></StyledLabel>
                                            </Col>
                                            <Col>
                                                <ButtonGroup toggle>
                                                    <ToggleButton
                                                        type="checkbox"
                                                        variant="outline-success"
                                                        name={option.value}
                                                        value='yes'
                                                        checked={locationDetails.options.includes(option.value)}
                                                        onChange={(e) => onOptionChange(e.currentTarget)}
                                                    >
                                                        Yes
                                                    </ToggleButton>
                                                </ButtonGroup>
                                                <ButtonGroup toggle className='ml-3' >
                                                    <ToggleButton
                                                        type="checkbox"
                                                        variant="outline-danger"
                                                        name={option.value}
                                                        value='no'
                                                        checked={locationDetails.notSelectedOptions.includes(option.value)}
                                                        onChange={(e) => onOptionChange(e.currentTarget)}
                                                    >
                                                        No
                                                    </ToggleButton>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    ))}
                                </Container>
                            }
                            {(steps.currentStep === 2 && !location) &&
                                <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                    <Row className='mx-0 my-3'>
                                        <Col lg='12' className='px-1'>
                                            <StyledLabel>Select Subscription plan</StyledLabel>
                                            <hr className='mt-0' />
                                        </Col>
                                        <Col lg='6' className='px-1'>
                                            <ButtonGroup toggle className='w-100'>
                                                <ToggleButton
                                                    type="checkbox"
                                                    variant="outline-success"
                                                    name='OSHA'
                                                    value='OSHA'
                                                    checked={locationDetails.locSubscriptionPlan === 'OSHA'}
                                                    onChange={() => onModelChange('locSubscriptionPlan', 'OSHA')}
                                                >
                                                    OSHA
                                                </ToggleButton>
                                            </ButtonGroup>
                                        </Col>
                                        <Col lg='6' className='px-0'>
                                            <ButtonGroup toggle className='w-100'>
                                                <ToggleButton
                                                    type="checkbox"
                                                    variant="outline-success"
                                                    name='OSHA&SDS'
                                                    value='OSHA&SDS'
                                                    checked={locationDetails.locSubscriptionPlan === 'OSHA&SDS'}
                                                    onChange={() => onModelChange('locSubscriptionPlan', 'OSHA&SDS')}
                                                >
                                                    OSHA+SDS
                                                </ToggleButton>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Col lg='12' className='px-1'>
                                        <StyledLabel>Card Details</StyledLabel>
                                        <hr className='mt-0 mb-1' />
                                    </Col>
                                    <CardElement disableToEdit={true} stripe={stripe} />

                                    <Row className='mx-0 my-3 d-block'>
                                        <Col lg='6' className='px-0 d-flex'> <Input value={couponCode} onChange={(e) => setCouponCode(e.target.value)}
                                            className="my-0 mr-2" type="text" placeholder="Referral code" />
                                            <BsButton type="button" onClick={applyCoupon} variant="success" size='lg'>Apply</BsButton></Col>
                                        <Col lg='6' className='px-0 mt-2' >
                                            {appliedCoupon &&
                                                <Chip>
                                                    <span>{appliedCoupon}</span><i onClick={removeCoupon} className='fa fa-times'></i>
                                                </Chip>
                                            }
                                        </Col>
                                    </Row>

                                    <InsideCreditCardDiv className='my-2'>
                                        <h5>Your card will be charged</h5>
                                        <h3>$ {((appliedCoupon ? priceDetails.remaining : priceDetails.basePrice) / 100).toFixed(2)}</h3>
                                    </InsideCreditCardDiv>
                                </Container>
                            }
                            {(steps.currentStep === 2 && location) &&
                                <UserListWithAnnualDate selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} locationDetails={locationDetails} location={location} />
                            }
                            <Container>
                                {error.apiError &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                onClose={() => setError({ ...error, apiError: false, errorMsg: '' })} dismissible>
                                                <p className='w-100 mb-0'>{error.errorMsg ? error.errorMsg : locationDetails._id ? 'Error at updating location.' : 'Error at adding location.'}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                {error.dataError &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                <p className='w-100 mb-0'>{error.errorMsg}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                <Row className='mt-2'>
                                    <Col>
                                        <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                                    </Col>
                                    <Col>
                                        <Button disabled={saveOrUpdateInProgress} className='my-0' type='button' onClick={() => saveOrUpdate()}>{locationDetails._id ? (saveOrUpdateInProgress ? 'Updating...' : 'Update') : (saveOrUpdateInProgress ? 'Saving...' : steps.currentStep === steps.totalSteps ? 'Save' : 'Next >>')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal >
    );
}

export default AddEditLocation;