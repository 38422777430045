import { createGlobalStyle } from 'styled-components';
import arrow from '../src/assets/images/glyph-arrow-right-green.svg';
import HauoraRegular from '../src/assets/fonts/hauora/Hauora-Regular.ttf';
import HauoraBold from '../src/assets/fonts/hauora/Hauora-Bold.ttf';
import HauoraSemiBold from '../src/assets/fonts/hauora/Hauora-SemiBold.ttf';
import HauoraMedium from '../src/assets/fonts/hauora/Hauora-Medium.ttf';

import HausNeueMedium from '../src/assets/fonts/HausNeue/HausNeue-Medium.otf';
import HausNeueLight from '../src/assets/fonts/HausNeue/HausNeue-Light.otf';
import HausNeueRegular from '../src/assets/fonts/HausNeue/HausNeue-Regular.otf';
import 'font-awesome/css/font-awesome.min.css';

/**
 * @constant GlobalStyles
 */
const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueLight}") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueRegular}") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueMedium}") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueMedium}") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraRegular}") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraMedium}") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraBold}") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraSemiBold}") format("truetype");
  font-weight: 600;
}


* {
  font-family: "Hauora";  
  box-sizing: border-box;
}
p {
   font-family: "Haus Neue";
}
a {
  font-family: "Hauora";
  text-decoration: none;
}

.trainingMaterialsInfoModel {
  .modal-body {
      font-size: 16px !important;
  }
}

html,
body {
  --key-color: #4FB973;
  --secondary-key-color: #7FE281;
  --dark-text-color: #343747;
  --light-text-color: #7E818F;
  --destructive-color: #EA4141;
  --background-color: #F3F5F9;
  --navy-color: ##343747;

  color: var(--dark-text-color);
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-left: 25px;
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--background-color);
}

.dash-border{
      border: 2px dashed #EFEFEF;
      border-radius: 1rem;
}

.cursor-pointer {
    cursor: pointer !important;
}

.light-text {
  color: var(--light-text-color) !important;
}

.search-choice {
  display: none !important;
}

/* Percentage Wheel */

.training-progress-container {
  width: 50px;
  height: 50px;
}

.training-progress-label {
  position: relative;
  font-weight: 700;
  font-size: 12px;
  color: var(--key-color);
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 40px;
}

.training-progress-track {
  position: relative;
  top: 5px;
  left: 5px;
  text-align: center;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 100%;
  background-color: white;
}

.training-progress-fill {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;

  background-color: var(--key-color);
  background-image:
      linear-gradient(91deg, transparent 50%, #EFEFEF 50%),
      linear-gradient(90deg, #EFEFEF 50%, transparent 50%);
}

/* Layout */

#primary-content-container {
  animation: fade-in 0.5s ease-out;
  -webkit-animation: fade-in 0.5s ease-out;
  -moz-animation: fade-in 0.5s ease-out;
}

.navigation-sidebar+#primary-content-container {
  margin-left: 50px;
}

.content-container {
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10);
  border-radius: 3px;
}

.empty-content-container {
  color: var(--light-text-color);
  text-align: center;
  max-width: 270px;
}

.split-view {
  width: 100%;
  height: 100vh;
  min-height: calc(100vh - 40px);
  flex-grow: 0;
}

.split-view,
.split-view>.content-container {
  display: flex;
  height: auto;
}

.split-view .empty-content-container {
  align-self: center;
  margin: 60px;
}

.split-view .master-panel {
  width: 421px;
  flex-shrink: 0;
  background: white;
  z-index: 300;
}

.content-container .master-panel {
  border-radius: 3px;
}

.split-view .detail-panel {
  width: 350px;
  padding: 15px 30px 15px 40px;
  border-left: 0.5px solid #D3D3D3;
  background: white;
  z-index: 200;
}

.split-view .detail-panel:not(.entity-edit) {
  animation: fade-in 0.15s ease-out, split-view-detail-panel-slide-in 0.15s cubic-bezier(0.105, 0.640, 0.345, 1.005);
  -webkit-animation: fade-in 0.15s ease-out, split-view-detail-panel-slide-in 0.15s cubic-bezier(0.105, 0.640, 0.345, 1.005);
  -moz-animation: fade-in 0.15s ease-out, split-view-detail-panel-slide-in 0.15s cubic-bezier(0.105, 0.640, 0.345, 1.005);
}

@keyframes split-view-detail-panel-slide-in {
  from {
      margin-left: -50px;
  }

  to {
      margin-left: 0;
  }
}

.content-container .detail-panel {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.content-container .detail-panel .input-group {
  width: 100%;
}

.content-container .input-group,
.content-container .detail-panel input[type="text"],
.content-container .detail-panel input[type="email"],
.content-container .detail-panel input[type="phone"],
.content-container .detail-panel .chosen-container,
.content-container .detail-panel a.chosen-single {
  width: 100% !important;
}

.split-view .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.split-view .header .filled-button {
  margin-left: 10px;
}

.split-view .header .filled-button:first-of-type {
  margin-left: auto;
}

.split-view .master-panel .header {
  padding: 15px 18px;
}

.split-view .detail-panel .header h2 {
  margin: 6px 0px 0px 0px;
}

.split-view .master-panel .table-header {
  padding-left: 50px;
}

.split-view .master-panel .master-table-section a.master-table-row {
  display: flex;
  flex-wrap: wrap;
  min-height: 38px;
  line-height: 38px;
  padding-left: 50px;
  padding-right: 25px;
  justify-content: space-between;
  cursor: pointer;

  overflow: hidden;

  font-weight: 400;
  color: var(--dark-text-color);
  text-decoration: none;
}

.split-view .master-panel .master-table-section a.master-table-row:not(.selected):not(:last-of-type)::after {
  content: "";
  width: calc(100% + 25px);
  height: 1px;
  background: #EAEAEA;
  margin-top: 15.5px;
  margin-right: -25px;
}

.split-view .master-panel .master-table-section:not(:last-of-type) a.master-table-row:last-of-type::after {
  display: none;
}

.split-view .master-panel a.master-table-row:not(.selected):hover,
.split-view .master-panel a.master-table-row:not(.selected):focus {
  background: var(--background-color);
  outline: none;
}

.split-view .master-panel a.master-table-row.selected:hover,
.split-view .master-panel a.master-table-row.selected:focus {
  outline: none;
}

.split-view .master-panel a.master-table-row.selected {
  background: var(--key-color);
  color: white;
  font-weight: 600;
  z-index: 40;
}

.split-view .master-panel .master-table-section a.master-table-row p {
  height: 1em;
}

.split-view .master-panel .master-table-section a.master-table-row.selected p {
  color: white;
}

.split-view .detail-panel hr {
  width: calc(100% + 31px);
  margin-right: -30px;
}

.split-view .detail-panel li {
  margin: 10px 0;
}

.input-group {
  margin: 13px 0px;
}

.alert-overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  animation: alert-overlay-fade-in 0.25s ease-out;
  -webkit-animation: alert-overlay-fade-in 0.25s ease-out;
  -moz-animation: alert-overlay-fade-in 0.25s ease-out;
}

@keyframes alert-overlay-fade-in {
  from {
      background: transparent
  }

  to {
      background: rgba(0, 0, 0, 0.2)
  }
}

.alert {
  max-width: 406px;
  padding: 20px;

  animation: alert-fade-scale-in 0.25s ease-out;
  -webkit-animation: alert-fade-scale-in 0.25s ease-out;
  -moz-animation: alert-fade-scale-in 0.25s ease-out;
}

@keyframes alert-fade-scale-in {
  from {
      opacity: 0;
      transform: scale(0.9, 0.9);
  }

  to {
      opacity: 1;
      transform: none;
  }
}

.alert h3 {
  margin-top: 0;
}

.button-layout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.button-layout-container.right-aligned {
  justify-content: flex-end;
}

.button-layout-container.right-aligned .filled-button {
  margin-left: 14px;
}

.button-layout-container.even-space-between {
  justify-content: space-between;
}

.button-layout-container.even-space-around {
  justify-content: space-around;
}

/* Animations */

@keyframes fade-in {
  from {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

/* Text Styles */

h2 {
  font-weight: 600;
  font-size: 25px;
  color: var(--dark-text-color);
  letter-spacing: 0;
  font-family: 'Haus Neue'
}

.empty-content-container h2 {
  color: var(--light-text-color);
  font-weight: 600;
  font-size: 18px;
}

h3 {
  font-weight: 600;
  font-size: 15px;
}

.body-light {
  font-size: 12px;
  color: var(--light-text-color);
}

.label-text {
  font-size: 11px;
  color: var(--light-text-color);
}

a {
  font-weight: 600;
  color: var(--key-color);
}

a:hover,
a:focus {
  color: var(--key-color);
}

a:not([href="#"]):focus {
  outline: none;
}

/* a {
  &::after {
    content: '';
    display: inline-block;
    width: calc(1em - 1px);
    height: calc(1em - 3px);
    margin-left: 5px;
    background-image: url("${arrow}");
    background-size: cover;
    background-repeat: no-repeat;
    transition: margin-left 0.25s ease-out;
  }
  &:hover {
    &::after {
      margin-left: 10px; 
    }
  }
} */

.nav-link {
  &::after {
      display: none;
  }
}

a.disclosure::after {
  content: "";
  display: inline-block;
  width: calc(1em - 1px);
  height: calc(1em - 3px);
  margin-left: 5px;
  background-image: url('/images/glyph-arrow-right-green.svg');
  background-size: cover;
  background-repeat: no-repeat;

  transition: margin-left 0.25s ease-out;
}

a.disclosure:hover::after {
  margin-left: 10px;
}

a.download::before {
  content: "";
  display: inline-block;
  width: calc(1em - 3px);
  height: calc(1em - 2px);
  margin-right: 8px;
  background-image: url('/images/glyph-arrow-down-green.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.input-title {
  display: block;
}

.input-hint {
  margin-left: 8px;
  margin-top: 5px;
}

.form-feedback {
  font-weight: 600;
  animation: fade-in 0.25s ease-out;
}

.form-feedback.error {
  color: #e82a2f;
}

.form-feedback.success {
  color: var(--key-color);
}

.header-label {
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
}

.join-code {
  font-family: 'Oxygen Mono', monospace;
  text-transform: uppercase;
}

/* Buttons */

.filled-button {
  border-radius: 4px;
  padding: 6px 10px;
  flex-grow: 0;
  min-width: 50px;
  height: 29px;

  font-family: 'Hauora';
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;

  /* Remove default WebKit appearance */
  -webkit-appearance: none;

  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #EAEAEA;
  color: #7f808f;
  text-align: center;
  transition: box-shadow 0.2s;
}

.filled-button:not(:disabled):hover,
.filled-button:not(:disabled):focus {
  cursor: pointer;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.18);
}

.filled-button:not(:disabled):active {
  background: #EAEAEA;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

.filled-button:active,
.filled-button:focus {
  outline: none;
}

.filled-button:disabled {
  cursor: not-allowed;
  background: #FBFBFB;
  border: 0.5px solid #EAEAEA;
  color: #BCBCBC;
}

.filled-button.button-destructive:disabled,
.filled-button.button-highlight:disabled {
  color: rgba(255, 2555, 255, 0.6);
}

.filled-button.button-highlight {
  background: var(--key-color);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: none;
  color: white;
  text-align: center;
  transition: box-shadow 0.2s;
}

.filled-button.button-highlight:not(:disabled):active {
  background: #379E5A;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

.filled-button.button-destructive {
  border: none;
  background-color: #E0001f;
  color: white;
}

.filled-button.button-destructive:not(:disabled):active {
  background: #C73131;
}

button.close-button {
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  outline: 0;

  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;

  background-image: url('/images/glyph-close-dark-gray.svg');
  background-color: #EDEDED;
  background-size: 8px 8px;
  background-position: center center;
  background-repeat: no-repeat;

  transition: background-color 0.15s ease-out;
  -webkit-transition: background-color 0.15s ease-out;
  -moz-transition: background-color 0.15s ease-out;
}

button.close-button:hover,
button.close-button:focus {
  background-image: url('/images/glyph-close-white.svg');
  background-color: var(--light-text-color);
}

.activity-indicator {
  width: 24px;
  height: 24px;
}

.activity-indicator::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('/images/activity-indicator-normal.png');
  background-size: cover;
  background-repeat: no-repeat;

  animation: activity-indicator 1s linear infinite;
  -webkit-animation: activity-indicator 1s linear infinite;
  -moz-animation: activity-indicator 1s linear infinite;
}

.activity-indicator.white::after {
  background-image: url('/images/activity-indicator-white.png');
}

@keyframes activity-indicator {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(359deg);
  }
}

/

/* Date Picker Fields */

datepicker {
  width: auto !important;
}

datepicker input[type="text"] {
  cursor: pointer;
  background-image: url('/images/glyph-calendar-green.svg');
  background-size: 16px 16px;
  background-position: right 5px center;
  background-repeat: no-repeat;
}

._720kb-datepicker-calendar {
  background: white !important;
  border: 0.5px solid #D3D3D3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10) !important;
  border-radius: 3px !important;
}

._720kb-datepicker-calendar-body {
  font-family: 'Open Sans' !important;
  color: var(--dark-text-color) !important;
  font-weight: 400 !important;
}

._720kb-datepicker-calendar-header {
  background: white !important;
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
}

._720kb-datepicker-calendar-days-header {
  background: white !important;
}

._720kb-datepicker-calendar-days-header div {
  font-family: 'Open Sans' !important;
  color: var(--light-text-color) !important;
  font-weight: 400 !important;
}

._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background: var(--key-color) !important;
  color: white !important;
  font-weight: 700 !important;
  border-radius: 3px !important;
}

/* Chosen Dropdown */

.chosen-container {
  min-width: 200px;
}

.chosen-container-single a,
.chosen-container-single a:hover {
  text-decoration: none;
}

.chosen-container-single a.chosen-single span {
  line-height: 1.4em;
}

.chosen-container-single .chosen-single div b {
  background-image: url('/images/glyph-drop-gray.svg');
  background-size: 11px 5px;
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
}

.chosen-drop li.group-result.group-result {
  height: 22px;
  background: #FBFBFB;
  border-top: 0.5px solid #D3D3D3;
  border-bottom: 0.5px solid #D3D3D3;
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 0 14px;
}

.chosen-drop li.group-result.group-result:last-of-type,
.chosen-results li.active-result:last-of-type {
  border-bottom: none;
}

.chosen-drop ul li.active-result.highlighted {
  background: var(--key-color);
  color: white;
  font-weight: 600;
}

.chosen-container .chosen-drop {
  background: #FFFFFF;
  border: 0.5px solid #D3D3D3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10);
  border-radius: 3px;
  overflow: hidden;
}

.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
}

.chosen-container .chosen-results li {
  font-size: 12px;
  line-height: 28px;
  border-bottom: 0.5px solid #D3D3D3;
  padding: 0px 13px;
}

.chosen-container .chosen-results li.group-result {
  font-weight: 600;
}

.chosen-container .chosen-results li em {
  font-weight: 600;
}

/* Checkboxes */

input[type="checkbox"]+label {
  margin-left: 10px;
}

input[type="checkbox"]:disabled+label {
  color: var(--light-text-color);
  cursor: not-allowed;
}

.checkbox p.input-hint {
  margin-left: 30px;
  margin-top: 0;
}

input[type="checkbox"] {
  margin: 0 !important;
}

/* Checkbox off */
* input[type="checkbox"] {
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  padding: 0;

  /* Remove default styles */
  -webkit-appearance: none;
  box-shadow: none;
  border: none;

  border-radius: 4px;
  background: var(--background-color);
  border: 0.5px solid #D3D3D3;
}

* input[type="checkbox"]:focus {
  border: 0.5px solid var(--key-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(79, 185, 115, 0.30);
}

* input[type="checkbox"]:active {
  background: #ECECEC;
  border: 0.5px solid #BDBDBD;
}

* input[type="checkbox"]:checked:after {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background-image: url('/images/glyph-checkmark-white.svg');
  background-size: 10px 10px;
  background-position: center center;
  background-repeat: no-repeat;
}

* input[type="checkbox"]:checked {
  background: var(--key-color);
  border: none;
}

* input[type="checkbox"]:checked:active {
  background: #379E5A;
  box-shadow: none;
}

/* Checkbox off disabled */
* input[type="checkbox"]:disabled {
  background: white;
  border: 0.5px solid #D6D6D6;
}

/* Checkbox on disabled */
* input[type="checkbox"]:checked:disabled {
  background: #D6D6D6;
  border: none;
}

/* Checkbox on disabled active */
* input[type="checkbox"]:checked:disabled {
  background: #D6D6D6;
}

.segmented-control {
  display: inline-flex;
  flex-direction: row;
  background: var(--background-color);
  border: 0.5px solid #D3D3D3;
  border-radius: 4px;
  overflow: hidden;
}

.segmented-control+.label-text {
  margin-left: 10px;
}

.segmented-control .segment:not(:first-of-type) {
  border-left: 0.5px solid #D8D8D8;
}

input[type="radio"].segment {
  -webkit-appearance: none;
  display: none;
}

.fa {
  cursor: pointer !important;
}

/* Segment off */
input[type="radio"].segment+label {
  margin: 0;
  padding: 6px 15px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  color: var(--light-text-color);
  letter-spacing: 0;
  text-align: center;
  transition: background 0.15s linear;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Segment off hover */
input[type="radio"].segment:not(:checked)+label:hover {
  background: #EBEBEB;
}

/* Segment off active */
input[type="radio"].segment:not(:checked):active+label {
  background: #D7D7D7;
}

/* Segment on */
input[type="radio"].segment:checked+label {
  background: var(--key-color);
  color: white;
  font-weight: 600;
  text-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.15);
}

/* Segment on active */
input[type="radio"].segment:checked:active+label {
  background: #379E5A;
}

/* Text area */

textarea {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  border: 0.5px solid #D3D3D3;
  border-radius: 4px;

  transition: border 0.15s linear, box-shadow 0.15s linear;
  -webkit-transition: border 0.15s linear, box-shadow 0.15s linear;
  -moz-transition: border 0.15s linear, box-shadow 0.15s linear;
}

input:focus,
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container-active .chosen-choices,
textarea:focus {
  border: 0.5px solid var(--key-color);
  outline: none;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(79, 185, 115, 0.30);
}

input.error {
  border: 0.5px solid var(--destructive-color);
}

input.error:focus {
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(235, 66, 66, 0.30);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a6a6a6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a6a6a6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a6a6a6;
}

hr {
  border: none;
  background: #D3D3D3;
  height: 1px;
}

.table-header {
  height: 22px;
  background: #FBFBFB;
  border-top: 0.5px solid #D3D3D3;
  border-bottom: 0.5px solid #D3D3D3;
  z-index: 50;
}

/* Primary Navigation */

nav.navigation-sidebar {
  position: fixed;
  width: 50px;
  height: 100%;
  /* 100% causes layout problems in Safari on iPad */
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 900;

  background: white;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.05);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;

  animation: navigation-sidebar-slide-in 0.5s cubic-bezier(0.105, 0.640, 0.345, 1.005);
  -webkit-animation: navigation-sidebar-slide-in 0.5s cubic-bezier(0.105, 0.640, 0.345, 1.005);
  -moz-animation: navigation-sidebar-slide-in 0.5s cubic-bezier(0.105, 0.640, 0.345, 1.005);

  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;

}

@keyframes navigation-sidebar-slide-in {
  from {
      left: -50px;
      opacity: 0;
  }

  to {
      left: 0;
      opacity: 1;
  }
}

.navigation-sidebar .navigation-item {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.navigation-sidebar .navigation-item:not(#account-navigation-item):not(#support-navigation-item) {
  margin: 10px 0px;
}

.navigation-sidebar .navigation-item a {
  display: block;
  width: 100%;
  height: 100%;
}

.navigation-sidebar .navigation-item a:not(:visited):focus {
  border: 0.5px solid var(--key-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(79, 185, 115, 0.30);
  border-radius: 5px;
}

.navigation-sidebar .navigation-item.selected:before {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  margin-top: 25px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: var(--key-color);
}

.navigation-sidebar .navigation-item#account-navigation-item a {
  display: block;
  color: white;
  font-size: 11px;
  font-weight: 800;
  text-decoration: none;
  background: #DCDEDD;

  transition: background 0.15s ease-in-out;
  -webkit-transition: background 0.15s ease-in-out;
  -moz-transition: background 0.15s ease-in-out;
  -o-transition: background 0.15s ease-in-out;

  mask: url('/images/sidebar-account-normal.svg');
  mask-size: 30px 30px;
  mask-position: center center;
  mask-repeat: no-repeat;

  -webkit-mask: url('/images/sidebar-account-normal.svg');
  -webkit-mask-size: 30px 30px;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
}

.navigation-sidebar .navigation-item#account-navigation-item:hover a,
.navigation-sidebar .navigation-item#account-navigation-item.selected a,
.navigation-sidebar .navigation-item#account-navigation-item a:focus {
  background: var(--key-color);
}

.navigation-sidebar .navigation-item[data-tip-title]:hover::after {
  content: attr(data-tip-title);
  display: inline-block;
  position: absolute;
  height: 21px;
  background: white;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.10);
  font-size: 11px;
  color: var(--light-text-color);
  letter-spacing: 0;
  margin-left: 33px;
  margin-top: -37px;
  line-height: 0px;
  padding: 13px 10px;
  border-radius: 2px;

  animation: fade-in 0.3s;
  -webkit-animation: fade-in 0.3s;
}

.account-navigation-sidebar-callout {
  position: absolute;
  width: 220px;
  margin-left: 58px;
  margin-top: 8px;

  background: white;
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10);
}

.account-navigation-sidebar-callout:before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  height: 14px;
  left: -5px;
  top: 10px;

  background-size: cover;
  background-position: right center;
  background-image: url('/images/account-callout-arrow.png');
}

.account-navigation-sidebar-callout>div {
  padding: 10px 20px;
}

.account-navigation-sidebar-callout #user-title-label {
  font-weight: 600;
}

.account-navigation-sidebar-callout #user-detail-label {
  font-size: 11px;
  color: var(--light-text-color);
}

.account-navigation-sidebar-callout #practice-list-container {
  background: #FBFBFD;
  border-top: 0.5px solid #E7E7E7;
}

.account-navigation-sidebar-callout #practice-list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 17px;
}

.account-navigation-sidebar-callout #practice-list-container ul li {
  color: var(--light-text-color);
  margin: 4px 0px;
  cursor: pointer;
}

.account-navigation-sidebar-callout #practice-list-container ul li.selected {
  font-weight: 600;
  color: var(--key-color);
}

.account-navigation-sidebar-callout #practice-list-container ul li.selected::before {
  content: '';
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-left: -11px;
  background-size: cover;
  background-position: center;
  background-image: url('/images/glyph-checkmark-green.svg');
}

.account-navigation-sidebar-callout #practice-list-container ul li img {
  width: 9px;
  height: 9px;
  margin-right: 6px;
  margin-left: -6px;
  margin-bottom: 3px;
}

.account-navigation-sidebar-callout #link-container {
  background: #FBFBFD;
  border-top: 0.5px solid #E7E7E7;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.account-navigation-sidebar-callout #link-container a {
  display: block;
  color: var(--light-text-color);
  font-weight: 400;
  margin: 4px 0px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
  text-wrap: inherit;
}

.hidden {
  display: none !important;
}

.roundedProfileImage {
  border-radius: 50px;
  max-width: 100%;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

*::selection {
  background: var(--key-color);
  color: white;
}

/* Error Pages */

#error-content-container {
  width: 380px;
  height: 100vh;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Sign In Page */

#login-layout-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#login {
  width: 327px;
  padding: 25px 37px;
}

#login *:not(.activity-indicator) {
  width: 100%;
}

#login p,
#login h2 {
  text-align: center;
  margin: 20px 0px;
}

#login .activity-indicator {
  margin: 10px auto;
}

/* Registration */

#register-link-container {
  text-align: center;
  margin: 20px 0;
}

#registration-layout-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
}

#practice-nav {
  width: 279px;
  padding: 25px;
  margin-right: 27px;
  box-sizing: border-box;
}

a.registration-nav-item {
  display: block;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
  margin: 10px 0;
  cursor: pointer;
}

.registration-nav-item .number {
  display: inline-block;
  margin-right: 9px;
  font-weight: 600;
  font-size: 12px;
  color: var(--light-text-color);
}

.registration-nav-item .number.selected {
  width: 17px;
  height: 17px;
  font-size: 12px;
  border-radius: 50%;
  background: var(--key-color);
  color: white;
  text-align: center;
  margin-left: -5px;
  margin-right: 4px;
}

.registration-nav-item img {
  width: 12px;
  height: 12px;
  margin-left: -2px;
  margin-bottom: 3px;
  margin-right: 6px;
}

.practice_info {
  width: 702px;
  padding: 22px 53px;
}

#billing-details-layout-container {
  display: flex;
  flex-direction: row;
  margin: 25px 0px;
}

#billing-details-layout-container #payment-method-layout-container,
#billing-details-layout-container #billing-options-layout-container {
  margin-left: 52px;
}

.payment-method-form {
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);
  border: 0.5px solid #D6D7D9;
  width: 266px;
}

.payment-method-form>input {
  width: 100%;
}

.payment-method-form #payment-method-field-layout-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.payment-method-form #payment-method-field-layout-container input#payment-method-cvc-field::before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 19px;
  background-image: url('/images/cvc-hint-icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Onboarding */

#onboarding-walkthrough-layout-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: flex-start;
}

.onboarding-walkthrough-container {
  max-width: 400px;
  padding: 20px 35px;
  align-self: center;
  margin-left: 20px;
}

.onboarding-walkthrough-container h2 {
  margin-top: 0px;
  margin-bottom: 20px;
}

.onboarding-walkthrough-container .button-layout-container {
  width: 100%;
  margin-top: 20px;
}

.onboarding-walkthrough-container .button-layout-container .filled-button {
  width: 55px;
}

.onboarding-walkthrough-container .button-layout-container .filled-button.button-highlight {
  width: 96px;
  margin-left: auto;
}

/* Account Tab */

#account-primary-content-container {
  max-width: 835px;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

#account-navigation-content-container {
  text-align: center;
}

#account-navigation-content-container #user-content-container {
  min-width: 285px;
  padding: 25px 45px 10px 45px;
}

#account-navigation-content-container #user-content-container #user-title {
  font-weight: 600;
  font-size: 18px;
}

#account-navigation-content-container #navigation-link-container {
  text-align: left;
  padding: 15px 40px 15px 50px;
  background: #FBFBFD;
  border-top: 0.5px solid #E7E7E7;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

#account-navigation-content-container #navigation-link-container ul {
  list-style: none;
  padding: 0;
  border: 0;
  margin: 0;
}

#account-navigation-content-container #navigation-link-container ul li {
  cursor: pointer;
  margin: 8px 0px;
}

#account-navigation-content-container #navigation-link-container ul li:not(.selected) a {
  color: var(--light-text-color);
  font-size: 12px;
  font-weight: 400;
}

#account-navigation-content-container #navigation-link-container ul li.selected:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: -15px;
  margin-right: 5px;
  margin-top: -4px;
  background-image: url('/images/glyph-checkmark-green.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.account-master-content-container {
  width: 528px;
  padding: 20px 50px;
}

form #safety-equipment-form-layout-container {
  display: grid;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-columns: 0.5fr 0.5fr;
  margin: 20px 0px;
}

form #safety-equipment-form-layout-container div.checkbox {
  margin: 0;
}

/* Today Tab */

#today-primary-content-container {
  width: 100%;
  height: 100vh;
  max-width: 870px;
  margin: auto;
  padding: 50px 0px 83px 0px;
}

#header-layout-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 26px;
}

#header-layout-container #header-user-label-container {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  color: var(--light-text-color);
}

#header-layout-container #header-user-label-container a {
  font-size: 15px;
}

#today-layout-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#today-layout-container .header h2 {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
}

#today-layout-container .today-item-primary {
  width: 100%;
  margin-right: 37px;
}

.today-item-primary .split-view {
  min-height: auto;
}

.today-item-primary .split-view .master-panel .header {
  padding: 13px 25px 13px 47px;
}

.today-item-primary .split-view .detail-panel {
  max-width: 100%;
  width: auto;
}

.today-item-primary .split-view .detail-panel .header {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 18px;
}

.today-item-primary .split-view .detail-panel .header button {
  flex: 0 0 auto;
  margin-top: 10px;
}

.today-item-primary .split-view .detail-panel .header h2 {
  margin-left: 14px;
}

.today-item-primary .table-header {
  padding-left: 47px;
}

.today-secondary-layout-container {
  min-width: 411px;
  display: flex;
  flex-direction: column;
}

#today-layout-container .today-item-secondary {
  width: 100%;
  margin-bottom: 38px;
}

#today-layout-container .today-item-secondary.issue-notice::before {
  /*   border-top: 5px solid var(--destructive-color); */
  content: "";
  display: block;
  width: 100%;
  height: 5px;
  background: var(--destructive-color);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.today-item-secondary .header {
  padding: 13px 25px 13px 47px;
  border-bottom: 0.5px solid #D3D3D3
}

.today-item-secondary .header img {
  margin-top: 14px;
  float: right;
}

.today-item-secondary .content {
  padding: 12px 25px 30px 47px;
}

.today-item-secondary #training-content-layout-container {
  display: flex;
}

.today-item-secondary #training-content-layout-container .training-progress-container {
  width: 50px;
  margin-right: 20px;
  flex-shrink: 0;
  margin-top: 20px;
}

.training-progress-goal-label {
  font-weight: 400;
  font-size: 25px;
  color: var(--light-text-color);
  letter-spacing: 0;
}

/* Training Tab */

#video-primary-content-container {
  width: 100%;
  height: 100vh;
  padding: 30px;
}

#video-primary-content-container .content-container {
  padding: 30px 50px;
  min-height: 100%;
  overflow-y: scroll;
}

#video-primary-content-container .content-container .header {
  display: flex;
  margin-bottom: 30px;
}

#video-primary-content-container .content-container .header .training-progress-container {
  margin-right: 25px;
  flex: 0 0 auto;
}

#video-primary-content-container .content-container .header h2,
#video-primary-content-container .content-container .header p {
  margin: 2px;
}

#video-primary-content-container .content-container .table-header {
  width: calc(100% + 100px);
  margin: 0px -50px;
  padding: 0px 50px;
}

#video-primary-content-container .content-container .video-table-section {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 25% 25% 25% 25%;

  padding: 27px 0px 10px 0px;
}

#video-primary-content-container .video-table-section:empty {
  padding: 0px;
}

.video-table-section .video-table-item {
  width: 100%;
  cursor: pointer;
}

.video-table-section .video-table-item:not(:last-of-type) {
  padding-right: 10px;
}

.video-table-section .video-table-item:not(:first-of-type) {
  padding-left: 10px;
}

.video-table-section .video-table-item .video-item-poster-container {
  width: 100%;
  padding-bottom: 56.25%;

  border-radius: 8px;
  background-color: var(--background-color);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10);
  padding: 10px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.video-table-item .video-item-poster-container .video-play-button {
  width: 100%;
  height: 100%;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  background: transparent;

  background-image: url('/images/glyph-play-white.svg');
  background-size: 28px 31px;
  background-position: center center;
  background-repeat: no-repeat;
}

.video-table-section .video-table-item .video-item-poster-container .video-item-progress-container {
  width: 100%;
  height: 4px;
  margin-top: calc(56.25% - 10px);
}

.video-table-section .video-table-item .video-item-poster-container input[type="range"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  box-shadow: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;

  height: 4px;
  background-color: rgba(0, 0, 0, 0.30);
  border-radius: 2px;
}

.video-table-item input[type="range"]::-webkit-slider-thumb {
  width: 0;
  height: 0;
  display: none;
}

.video-table-item input[type="range"]::-moz-range-thumb {
  display: none;
  width: 0;
  height: 0;
}

.video-table-item input[type="range"]::-moz-range-track {
  display: none;
}

.video-table-item .video-item-title {
  margin: 14px;
  font-weight: 600;
  font-size: 15px;
}

#video-player-primary-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  color: white;
}

@supports (-webkit-backdrop-filter: blur()) {

  #video-player-primary-container {
      -webkit-backdrop-filter: blur(30px);
  }

}

#video-player-primary-container #video-content-container .header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 36px;
}

#video-player-primary-container #video-content-container .header #progress-header-item {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#video-player-primary-container #player-close-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 78px;
  height: 29px;
  margin-right: auto;

  color: white;
  font-weight: 600;
  font-size: 13px;

  padding: 0;
  border-radius: 17px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: transparent;

  transition: border 0.2s ease-out;
  -webkit-transition: border 0.2s ease-out;
  -moz-transition: border 0.2s ease-out;
}

#video-player-primary-container #player-close-button::before {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 8px;
  margin-left: 5px;

  background-image: url('/images/glyph-close-white.svg');
  background-size: 9px 9px;
  background-position: center center;
  background-repeat: no-repeat;
}

#video-player-primary-container #player-close-button:hover,
#video-player-primary-container #player-close-button:focus {
  border: 1px solid var(--key-color);
  outline: none;
}

#video-content-container {
  height: 100vh;
  width: 646px;
  margin: 0 auto;
  padding-top: 50px;
}

#video-content-container label {
  color: #868787;
}

#video-content-container h2 {
  color: white;
}

.video-player-container {
  position: relative;
  width: 646px;
  height: 364px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.video-player-container #video-player {
  width: 646px;
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

video::-webkit-media-controls {
  display: none !important;
}

.video-player-container .video-player-controls {
  width: 100%;
  height: 93px;
  position: absolute;
  bottom: 0px;
  z-index: 60;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 30px 22px 30px;

  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.00) 41%, rgba(0, 0, 0, 0.60) 100%);
}

.autohide {
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.video-player-container:hover .autohide {
  opacity: 1;
}

.video-player-controls *:not([hidden]) {
  display: block;
  margin: 0px 10px;
  flex: 0 0 auto;
}

.video-player-controls .activity-indicator {
  width: 16px;
  height: 16px;
}

.activity-indicator.white::after {
  background-position: center center;
  background-size: 16px 16px;
  background-image: url('/images/activity-indicator-white.png');
}

.video-player-controls #video-progress-bar {
  flex: auto;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;

  height: 2px;
  margin-bottom: 7px;
  background: rgba(255, 255, 255, 0.2);

}

.video-player-controls #video-progress-bar:focus {
  outline: none;
}

.video-player-controls #video-progress-bar:focus::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  background: var(--key-color);
}

.video-player-controls #video-progress-bar:focus::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: var(--key-color);
}

.video-player-controls #video-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  width: 6px;
  height: 6px;

  background: white;
  border-radius: 50%;
  cursor: pointer;

  transition: width 0.1s ease-out, height 0.1s ease-out;
  -webkit-transition: width 0.1s ease-out, height 0.1s ease-out;
}

.video-player-controls #video-progress-bar::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;

  width: 6px;
  height: 6px;

  background: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  -moz-transition: width 0.1s ease-out, height 0.1s ease-out;
}

.video-player-controls #video-progress-bar::-webkit-slider-thumb:hover,
.video-player-controls #video-progress-bar::-webkit-slider-thumb:active {
  width: 10px;
  height: 10px;
}

.video-player-controls #video-progress-bar::-moz-range-thumb:hover,
.video-player-controls #video-progress-bar::-moz-range-thumb:active {
  width: 10px;
  height: 10px;
}

.video-player-controls p#video-time-label {
  font-family: 'Hauora';
  font-size: 15px;
  color: white;
  text-align: center;
  line-height: 16px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.20);
}

.video-player-controls button {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;

  -webkit-appearance: none;
  border: none;
  outline: none;

  background: white;

  mask-size: 16px 16px;
  mask-position: center center;
  mask-repeat: no-repeat;

  -webkit-mask-size: 16px 16px !important;
  -webkit-mask-position: center center !important;
  -webkit-mask-repeat: no-repeat !important;

  cursor: pointer;
}

.video-player-controls button:focus:not(:active) {
  background: var(--key-color);
}

.video-player-controls button:active {
  opacity: 0.6;
}

.video-player-controls button#video-play-state-button {
  mask: url('/images/glyph-pause-white.svg');
  -webkit-mask: url('/images/glyph-pause-white.svg');
}

.video-player-controls button#video-play-state-button.play {
  mask: url('/images/glyph-play-white.svg');
  -webkit-mask: url('/images/glyph-play-white.svg');
}

.video-player-controls button#video-caption-button {
  mask: url('/images/glyph-closed-captions-white.svg');
  -webkit-mask: url('/images/glyph-closed-captions-white.svg');
}

.video-player-controls button#video-caption-button.on {
  mask: url('/images/glyph-closed-captions-on-white.svg');
  -webkit-mask: url('/images/glyph-closed-captions-on-white.svg');
}

.video-player-controls button#video-pip-button {
  mask: url('/images/glyph-picture-in-picture-white.svg');
  -webkit-mask: url('/images/glyph-picture-in-picture-white.svg');
}

.video-player-controls button#video-airplay-button {
  mask: url('/images/glyph-airplay-white.svg');
  -webkit-mask: url('/images/glyph-airplay-white.svg');
}

.video-player-controls button#video-expand-button {
  mask: url('/images/glyph-expand-white.svg');
  -webkit-mask: url('/images/glyph-expand-white.svg');
}

.video-player-controls button#video-expand-button.full {
  mask: url('/images/glyph-collapse-white.svg');
  -webkit-mask: url('/images/glyph-collapse-white.svg');
}

@supports (-webkit-backdrop-filter: blur()) {

  .video-player-controls #video-progress-bar {
      -webkit-backdrop-filter: blur(1px);
  }

}

#video-player-primary-container #video-player-title-container {
  padding: 35px;
  color: white;
}

#video-player-primary-container #video-player-title-container p {
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

#video-player-primary-container #video-player-title-container h2 {
  color: white;
  margin: 0;
}

/* Records Tab */

#records-primary-content-container {
  display: flex;
  margin: 30px;
  align-items: flex-start;
}

#records-filter-content-container {
  width: 304px;
  flex: 0 0 auto;
  padding: 0px 30px;
  margin-right: 30px;
}

#records-filter-content-container .header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

#records-filter-content-container .filter-group {
  margin: 22px 0;
}

#records-filter-content-container .filter-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

#records-filter-content-container .filter-item input,
#records-filter-content-container .filter-item select,
#records-filter-content-container .filter-item datepicker,
#records-filter-content-container .filter-item .chosen-container {
  min-width: 50px;
  width: 149px !important;
  margin: 0;
}

#records-primary-content-container .split-view {
  min-height: calc(100vh - 60px);
}

#records-primary-content-container .master-panel {
  max-width: 383px;
}

#records-primary-content-container .master-panel .header {
  height: 60px;
  padding-left: 50px;
}

#records-primary-content-container .master-panel .header * {
  margin: 0;
}

#records-primary-content-container a.master-table-row {
  min-height: 63px;
  padding-right: 15px;
}

#records-primary-content-container a.master-table-row:not(.selected)::after {
  margin-top: 19.5px;
}

.record-table-row-badge {
  margin-left: -35px;
  margin-right: 13px;
}

#records-primary-content-container .split-view .detail-panel {
  width: 431px;
}

#records-primary-content-container .split-view .detail-panel div.header+p {
  margin: 10px 0px;
}

.split-view .master-panel#incident-report-content {
  max-width: 432px;
  padding: 15px 15px 15px 30px;
}

.split-view .master-panel#incident-report-content .header {
  padding: 0;
  height: auto;
}

.split-view .master-panel#incident-report-content .header+hr {
  width: calc(100% + 15px);
}

#incident-report-content form label {
  font-weight: normal;
}

#article-content-container {
  width: 590px;
  padding: 30px 65px;
}

#article-content-container div.activity-indicator {
  margin: auto auto;
}

#article-content-container *:not(a) {
  /* Prevent user selection to discourage copying of content */
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
}

#article-content-container h2 {
  margin-bottom: 25px;
}

#article-content-container h3 {
  font-size: 19px;
}

#article-content-container h4 {
  font-size: 15px;
  font-weight: 600;
}

#article-content-container p {
  line-height: 1.5em;
}

#article-content-container a {
  word-wrap: break-word;
}

#article-content-container ol,
#article-content-container ul {
  padding-left: 20px;
  margin: 10px 0px;
}

#article-content-container table td,
#article-content-container table th,
#setup-content-container table td,
#setup-content-container table th {
  border: 0.5px solid #E9E9E9;
}

#article-content-container table tr:nth-of-type(odd) td,
#setup-content-container table tr:nth-of-type(odd) td {
  background: #FAFAFA;
}

#article-content-container blockquote {
  font-size: 1em;
  color: var(--light-text-color);
  border-left: 2px solid #E9E9E9;
  padding: 3px 20px;
  margin: 10px 0px;
}

#article-content-container code {
  color: var(--dark-text-color);
  background: var(--background-color);
}

#article-content-container .footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

#article-content-container .footer>div {
  width: 40%;
}

#article-content-container .footer>div:last-of-type {
  text-align: right;
}

/* Administration Tab */

#administration-primary-content-container {
  display: flex;
  width: calc(100% - 218px);
}

#administration-primary-content-container>div {
  width: 100%;
}

.administration-layout-container {
  margin: 20px 20px 20px 198px;
}

#administration-primary-content-container #administration-navigation-sidebar {
  position: fixed;
  width: 178px;
  padding: 15px 24px;
  background: white;
  z-index: 800;
}

#administration-navigation-sidebar p.header-label {
  margin-top: 20px;
  margin-bottom: 4px;
}

#administration-navigation-sidebar a {
  display: block;
  height: 27px;
  line-height: 27px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  color: var(--dark-text-color);
}

#administration-navigation-sidebar a:hover,
#administration-navigation-sidebar a:focus {
  background: var(--background-color);
  text-decoration: none;
  outline: none;
}

#administration-navigation-sidebar a.selected {
  background: var(--key-color);
  color: white;
  font-weight: 600;
}

/* Administration Staff */

.detail-panel .notice {
  background: var(--background-color);
  border-radius: 3px;
  padding: 10px 13px;
}

.detail-panel .notice p {
  margin: 0;
}

.join-code-label {
  display: inline-block;
  border: 1px solid var(--dark-text-color);
  padding: 5px 8px;
  color: var(--dark-text-color);
}

.notice .join-code-label {
  margin-top: 10px;
}

.join-code-label.expired {
  color: #AAABB1;
  margin-right: 10px;
}

.join-code-label.expired::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: -9px;
  margin-bottom: 8px;
  background: #AAABB1;
}

/* Administration: Roles */

#safety-equipment-edit-list {
  list-style: none;
  padding: 0;
}

#safety-equipment-edit-list li {
  margin: 10px 0px;
}

#safety-equipment-edit-list li img {
  cursor: pointer;
  margin-right: 5px;
}

/* Administration Locations */

ul#location-attributes-list {
  list-style: none;
  padding: 0;
}

ul#location-attributes-list li::before {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 7px;
  background-size: cover;
  background-repeat: no-repeat;
}

ul#location-attributes-list li:not(.selected)::before {
  background-image: url('/images/glyph-close-dark.svg');
}

ul#location-attributes-list li.selected::before {
  background-image: url('/images/glyph-checkmark-dark.svg');
}

/* Administration Billing */

.administration-layout-container#billing-admin-layout-container {
  width: 100%;
  margin: 0 0 0 178px;
}

.administration-layout-container#billing-admin-layout-container>.content-container {
  padding: 25px 50px 20px 50px;
  margin: 20px;
}

#billing-admin-layout-container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

#billing-admin-layout-container .header h2 {
  margin: 0;
}

#billing-admin-layout-container #billing-details-layout-container {
  margin: 0;
}

#billing-invoice-table {
  margin: 0px -50px;
  width: calc(100% + 100px);
  padding: 0px 50px;
}

#billing-invoice-table tbody td {
  line-height: 41px;
  border-bottom: 0.5px solid #EAEAEA;
}

#billing-invoice-table td:first-of-type {
  padding-left: 50px;
}

#billing-invoice-table td:last-of-type {
  padding-right: 50px;
}

#support-callout-view {
  position: fixed;
  bottom: 8px;
  left: 58px;
  padding: 8px 12px;
  width: 260px;
  height: 140px;
}

#support-callout-view:focus-within {
  border: 0.5px solid var(--key-color);
  outline: none;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(79, 185, 115, 0.30);
}

#support-callout-view textarea,
#support-callout-view textarea:focus {
  width: 100%;
  resize: none;
  border: none;
  box-shadow: none;
  margin-bottom: 8px;
}

#support-callout-view #button-layout-container {
  margin: -8px -12px;
  border-top: 0.5px solid #E9E9E9;
  width: calc(100% + 24px);
  height: 30px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
}

#support-callout-view button {
  -webkit-appearance: none;
  border: none;
  background: transparent;
  width: 50%;
}

#support-callout-view button:hover {
  background: #FAFAFA;
}

#support-callout-view button {
  color: var(--light-text-color);
}

#support-callout-view button+button {
  border-left: 0.5px solid #E9E9E9;
  font-weight: 500;
  color: var(--key-color);
  cursor: pointer;
}

@media (-webkit-min-device-pixel-ratio: 2) {

  hr {
      height: 0.5px;
  }

  .content-container {
      border-width: 0.5px;
  }

  .account-navigation-sidebar-callout {
      border-width: 0.5px;
  }

  .split-view .master-panel .master-table-section a.master-table-row:not(.selected):not(:last-of-type)::after {
      height: 0.5px;
      margin-top: 15px;
  }

}

.CircularProgressbar {
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.percentageOfCircleToShow is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.btn-group>.btn.active {
  z-index: 0 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px LightGray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #D3D6DD;
  border-radius: 8px;
}

.spinner-modal {
  .modal-content {
      background: none;
      border: none;
  }
}

button {
  font-family: 'Hauora';
  font-weight: 500 !important;
}

.w-5{
  width:5% !important;
  word-break: break-word;
}
.w-10{
  width:10% !important;
  word-break: break-word;
}
.w-15{
  width:15% !important;
  word-break: break-word;
}
.w-20{
  width:20% !important;
  word-break: break-word;
}
.w-25{
  width:25% !important;
  word-break: break-word;
}

`;

export default GlobalStyles;
