import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import ReactMarkdown from 'react-markdown';

import { apiUrl } from '../../helpers';
import { Input } from '../../components/Input';
import EmbeddingIcon from '../../assets/images/Embedding.svg'
import { Spinner } from '../../components/Spinner';

const ChatContainer = styled.div`
    display: flex;
    width: 95%;
    background-color: #F3F5F9;
    margin: 6.5rem 0.5rem 0rem 6rem !important;
    height: 90vh;
    @media only screen and (max-width:460px) and (min-width:281px){
        width : 100%;
        display:block;
     }  
`;

const MessageContainer = styled.div`
  width:40vw;
  height:100%;
  border: 0.5px solid #4fb973;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);

    @media only screen and (max-width:460px) and (min-width:281px){
        width : 100%;
     } 
`;

const MessagePanel = styled.div`
    overflow-y:auto;
    height: 90%;
    width:98%;
`;

const QAPanel = styled.div`
    border-bottom: 0.5px solid #979797;
    .question{
        margin:0;
        strong{
            color:red;
        }
    }
    p {
        margin:0;
        font-family: "Hauora";
    }
    .answer{
        a{
            color: #007bff !important;
            word-break: break-all;
        }
        margin:0;
        .answer_label{
            font-weight: 600;
            color:#4fb973
        }
    }
`;

const InputPanel = styled.form`
    display:flex;
    justify-contains:between;
    width:98%;
    height:8.5%;
    border-top: 1px solid #d3d6dd;
`;

const NoteTextContainer = styled.div`
    width:60vw;
    display:flex;
    justify-content:center;
    @media only screen and (max-width:460px) and (min-width:281px){
        width : 100%;
        margin-top: 3rem;
     }
`;

const TextContainer = styled.div`
    width:80%;
    text-align: left;
    @media only screen and (max-width:460px) and (min-width:281px){
        width : 100%;
     } 
     .px-4{
        @media only screen and (max-width:460px) and (min-width:281px){
            padding-left:0 !important;
         } 
     }
     h1{
        @media only screen and (max-width:460px) and (min-width:281px){
            font-size:2rem;
            word-break: break-all;
         } 
     }
    p{
        font-size: 16px;
        font-weight: 400;
        margin-bottom:0;
    }
`;

const ErrorMessageContainer = styled.div`
    height:100%;
    width:40vw;
    border: 0.5px solid #4fb973;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    display:flex;
    align-items:center;
    text-align:center;
    @media only screen and (max-width:460px) and (min-width:281px){
        width : 100%;
     } 
`;

class ChatBox extends Component {
    state = {
        latestQuestion: "",
        chatHistory: [],
        loading: false,
        location: {},
        locationLoading: false
    };

    componentDidMount() {
        this.getLocationData();
    };

    getLocationData = async () => {
        try {
            const { currentUser } = this.props;
            this.setState({ locationLoading: true });
            const { data: { payload: location } } = await axios.get(`/api/v2/location/${currentUser.location._id}`);
            this.setState({ location, locationLoading: false });
        } catch (error) {
            this.setState({ locationLoading: false });
        }
    };

    onModalChange = (e) => {
        this.setState({
            latestQuestion: e.target.value
        });
    };

    formatStringWithUrls(str) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return str.replace(urlRegex, '<a target="_blank" href="$1">$1</a>');
    };

    getAnswer = async (e) => {
        e.preventDefault();
        try {
            const { loading, latestQuestion } = this.state;
            if (['clear', 'Clear', 'Quit', 'quit'].includes(latestQuestion)) {
                this.setState({ chatHistory: [], loading: false, latestQuestion: '' });
                return;
            };
            if (!loading && latestQuestion) {
                let { chatHistory, latestQuestion } = this.state;
                const { currentUser } = this.props;
                chatHistory.push({ question: latestQuestion, answer: 'Loading ...' });
                this.setState({ chatHistory, loading: true });
                this.scrollToBottom();
                const { data } = await axios.post(apiUrl(`/api/v1/ask`), { question: latestQuestion, location: currentUser.location._id });
                let lastQuestion = chatHistory.pop();
                lastQuestion.answer = data.answer;
                chatHistory.push(lastQuestion);
                this.setState({ chatHistory, latestQuestion: "", loading: false });
                this.scrollToBottom();
            };
        } catch (error) {
            this.setState({ loading: false });
            toast.error('Something went wrong.', { autoClose: false });
        }
    };

    startEmbedding = async () => {
        try {
            const { currentUser } = this.props;
            this.setState({ location: { ...this.state.location, embeddingStatus: 'In Progress' } });
            await axios.post(apiUrl(`/api/v1/data_embedding/${currentUser.location._id}`), {});
            this.getLocationData();
        } catch (error) {
            this.setState({ location: { ...this.state.location, embeddingStatus: 'Error' } });
            toast.error('Something went wrong.', { autoClose: false });
        }
    };

    scrollToBottom = () => {
        const el = document.getElementById("bottomTag");
        setTimeout(() => {
            el && el.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    render() {
        const { chatHistory, latestQuestion, loading, locationLoading, location } = this.state;
        return (
            <>
                {
                    locationLoading ?
                        <Spinner /> :
                        <ChatContainer>
                            {location.embeddingStatus !== 'Completed' ?
                                <ErrorMessageContainer>
                                    {!location.didCompleteLibrarySetup ?
                                        <h4 className='text-center w-100'>
                                            Your library setup is not yet complete.
                                            <br />
                                            Kindly complete it to start using MEDiCC.
                                        </h4> :
                                        <div className='text-center w-100'>
                                            {location.embeddingStatus === 'In Progress' ? <h4>
                                                Your data has not been processed yet,
                                                <br />
                                                processing is in progress. Kindly wait.
                                            </h4> : <h4 >
                                                Your data has not been processed yet,
                                                <br />
                                                please click below button and start processing.
                                            </h4>}
                                            <br />
                                            <button disabled={location.embeddingStatus === 'In Progress'} type='button' onClick={this.startEmbedding} className='btn btn-outline-success my-2 mr-2'>
                                                {
                                                    location.embeddingStatus === 'In Progress' ?
                                                        <>
                                                            Processing
                                                            <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="1" width="20" visible={true} />
                                                        </>
                                                        :
                                                        <>Start Processing
                                                            <img height="30" width="30" src={EmbeddingIcon} alt="Start embedding" title="Start embedding" />
                                                        </>
                                                }
                                            </button>
                                        </div>
                                    }
                                </ErrorMessageContainer> :
                                <MessageContainer>
                                    <MessagePanel className='mt-2 mx-2 p-1'>
                                        {
                                            chatHistory.map((chat, index) => (
                                                <QAPanel className='my-1' key={index}>
                                                    <b className='question'><strong>Q:&nbsp;</strong>
                                                        {chat.question}
                                                    </b>
                                                    <div className='answer'>
                                                        <span className='answer_label'>Ans:&nbsp;</span>
                                                        {/* <span dangerouslySetInnerHTML={{ __html: this.formatAnswer(chat.answer) }} /> */}
                                                        <ReactMarkdown>{chat.answer}</ReactMarkdown>
                                                    </div>
                                                </QAPanel>
                                            ))}
                                        <p id="bottomTag"></p>
                                    </MessagePanel>
                                    {location.embeddingStatus === 'Completed' &&
                                        <InputPanel onSubmit={this.getAnswer}>
                                            <Input
                                                placeholder="Begin your conversation with MEDiCC here..."
                                                name="message"
                                                className="m-2"
                                                value={latestQuestion}
                                                onChange={this.onModalChange}
                                                disable={loading}
                                                required
                                            />
                                            <button disabled={loading || location.embeddingStatus === 'In Progress'} type="submit" className='btn btn-outline-success my-2'>
                                                {loading ?
                                                    <RotatingLines
                                                        strokeColor="grey"
                                                        strokeWidth="5"
                                                        animationDuration="1"
                                                        width="20"
                                                        visible={true}
                                                    />
                                                    :
                                                    <i className="fa fa-paper-plane" />
                                                }
                                            </button>
                                            {location.isLibraryStepUpdated &&
                                                <button disabled={location.embeddingStatus === 'In Progress'} type='button' onClick={this.startEmbedding} className='btn btn-outline-success my-2 ml-2' >
                                                    {location.embeddingStatus === 'In Progress' ?
                                                        <RotatingLines
                                                            strokeColor="grey"
                                                            strokeWidth="5"
                                                            animationDuration="1"
                                                            width="20"
                                                            visible={true}
                                                        />
                                                        :
                                                        <img height="30" width="30" src={EmbeddingIcon} alt="Start embedding." title="Start embedding" />
                                                    }
                                                </button>
                                            }
                                        </InputPanel>
                                    }
                                </MessageContainer>}
                            <NoteTextContainer>
                                <TextContainer>
                                    <h1>
                                        Meet MEDiCC™, the Medical & Dental Intelligent Compliance Companion
                                    </h1>
                                    <div className="px-4">
                                        <p className='pb-4'>
                                            Now in Beta, MEDiCC enables conversational compliance assistance. MEDiCC is trained on the policies, procedures, and responsibilities of your specific practice and is here to save you time. MEDiCC will continue to get smarter and become more able as time goes on.
                                        </p>
                                        <p>
                                            Give it a try by asking MEDiCC something like “Who’s responsible for getting patients out in the event of a fire?” or “What do I do in the event of a chemical spill?”
                                        </p>
                                    </div>
                                </TextContainer>
                            </NoteTextContainer>
                        </ChatContainer>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser
});

export default connect(mapStateToProps)(ChatBox);