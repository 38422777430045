import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container, Spinner, Table } from 'react-bootstrap';
import { MainContainer, TableHead, TableContainer } from './StyleComponents';

const StaffLocations = ({ user, closePopUpModal }) => {

    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ apiError: false });

    useEffect(() => {
        getLocations();
    }, []);

    const getLocations = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/usersLocations/' + user._id);

            setLocations(payload);
            setLoading(false);
        } catch (error) {
            setError({ apiError: true });
            setLoading(false);
        }
    };

    const getPositions = (locationId) => {
        let positions = [];
        if (user.adminLocations && user.adminLocations.length && user.adminLocations.includes(locationId)) positions.push('Administration ');
        if (user.spiceOfficerLocations && user.spiceOfficerLocations.length && user.spiceOfficerLocations.includes(locationId)) positions.push('Safety Officer ');
        if (user.spiceOfficerLocations && user.spiceOfficerLocations.length && user.spiceOfficerLocations.includes(locationId)) positions.push('SPICE Officer ');
        if (user.atRiskLocations && user.atRiskLocations.length && user.atRiskLocations.includes(locationId)) positions.push('At Risk ');
        return positions.toString();
    };

    return (
        <MainContainer>
            <div className="d-flex justify-content-between">
                <h2>{user.fullName}'s Locations</h2>
                <i style={{ fontSize: '20px' }} className='fa fa-times text-secondary' onClick={closePopUpModal} />
            </div>
            {loading ?
                <Container className='text-center my-3'>
                    <Spinner animation="border" variant="success" />
                </Container> :
                <TableContainer>
                    <Table className='mb-0'>
                        <TableHead>
                            <tr>
                                <th className='w-25'>Name</th>
                                <th className='w-50'>Positions</th>
                                <th className='w-25'>Role</th>
                            </tr>
                        </TableHead>
                        <tbody>
                            {locations.length && locations.map((location) => (
                                <tr key={location._id}>
                                    <td className='w-25'>{location.name}</td>
                                    <td className='w-50'>{getPositions(location._id)}</td>
                                    <td className='w-25'>{location.role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TableContainer>
            }
        </MainContainer>
    );
}

export default StaffLocations;