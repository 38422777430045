import axios from "axios";
import { useEffect, useRef, useState } from "react";

import uploadDocGreen from '../../assets/images/upload-doc-green.svg';
import trash from '../../assets/images/trash.svg';
import { Alert, Col, Row } from "react-bootstrap";
import { StyledLabel, SubText, FileList, DocUploadContainer } from "./Style";

const DocUpload = ({ selectedFiles, setSelectedFiles }) => {
    const fileInputRef = useRef(null);
    // const [selectedFiles, setSelectedFiles] = useState([]);
    const [errors, setErrors] = useState({ fileSizeError: false, fileFormatError: false });

    useEffect(() => {
        setSelectedFiles([]);
    }, []);

    const handleFileChange = (event) => {
        const files = validate([...selectedFiles, ...Array.from(event.target.files)]);
        setSelectedFiles([...files]);
    };

    const validate = (files) => {
        let totalFileSize = 0;
        let fileSizeErrorCount = 0;
        let fileFormatErrorCount = 0;
        const allowedExtensions = /(\.doc|\.docx|\.xlsx|\.pdf|\.jpeg|\.jpg|\.png)$/i;

        files = files.map((file) => {
            if (file.size > 8000000) {
                file.inValid = true;
                fileSizeErrorCount++;
            };
            if (!allowedExtensions.exec(file.name)) {
                file.inValidFormat = true;
                fileFormatErrorCount++;
            };
            totalFileSize += file.size;
            return file;
        });
        if (totalFileSize > 19999999) {
            setErrors(prevState => ({
                ...prevState,
                totalFileSizeError: true
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                totalFileSizeError: false
            }));
        };

        setErrors(prevState => ({
            ...prevState,
            fileSizeError: fileSizeErrorCount ? true : false,
            fileFormatError: fileFormatErrorCount ? true : false
        }));

        return files;
    };

    const deleteFiles = (index) => {
        const tempFiles = [...selectedFiles];
        tempFiles.splice(index, 1);
        validate(tempFiles);
        setSelectedFiles(tempFiles);
    };

    const openFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <DocUploadContainer>
            <Row className='my-4'>
                <Col lg={12} mg={12} sm={12}>
                    <StyledLabel>
                        Upload Documents to Complete this Task
                    </StyledLabel>
                </Col>
                <Col onClick={openFileInput} lg={12} mg={12} sm={12} className='text-center dash-border py-3'>
                    <span className="cursor-pointer">
                        <img src={uploadDocGreen} alt="" />
                        <input
                            style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }}
                            name='documents'
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            multiple
                            type='file'
                            accept=".doc, .docx, .xlsx, .pdf, image/jpeg, image/png"
                        />
                        <SubText className="text-success mb-0">
                            Choose file(s) to upload
                        </SubText>
                        <SubText className="text-secondary mb-0">
                            DOC, XLSX, PDF, JPG, PNG type of file.
                            Choose one or more files to upload from your computer.
                        </SubText>
                    </span>
                </Col>
                <ol>
                    {
                        selectedFiles.map((file, index) => (
                            <FileList key={index} inValid={file.inValid || file.inValidFormat}>
                                {file.name}
                                <img onClick={() => deleteFiles(index)} className="mx-4" src={trash} alt="Delete" />
                            </FileList>
                        ))
                    }
                </ol>
                {
                    errors.fileSizeError &&
                    <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>
                            Some files' sizes are more than 8MB. Please select files that are smaller than 8MB.
                        </p>
                    </Alert>
                }
                {
                    errors.fileFormatError &&
                    <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>
                            Please select files with the following types: .doc, .xlsx, .pdf, .jpg, or .png. Files of other types are not supported.
                        </p>
                    </Alert>
                }
            </Row>
        </DocUploadContainer>
    )
};

export default DocUpload;