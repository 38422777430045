import React, { Component } from 'react';
import axios from 'axios';
import { Figure, Spinner, Col, Row, Alert, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Select from 'react-select';

import { SearchInput, WhiteButton, CustomCard, CardBody, IconContainer, ImageContainer, ContentHeader, AddButton } from './Style';
import { AddEditSafetyEquipment, Button } from '../../components';

import addIcon from '../../assets/images/add.svg';
import pencil from '../../assets/images/pencil.svg';
import trash from '../../assets/images/trash.svg';
import fluidResistantGown from '../../assets/safety-equipment/fluidResistantGown.svg';
import safetyGoggles from '../../assets/safety-equipment/safetyGoggles.svg';
import latexGloves from '../../assets/safety-equipment/latexGloves.svg';
import heavyDutyGloves from '../../assets/safety-equipment/heavyDutyGloves.svg';
import mask from '../../assets/safety-equipment/mask.svg';
import customSafetyEquipment from '../../assets/safety-equipment/customSafetyEquipment.svg';
import faceShieldApron from '../../assets/safety-equipment/faceShieldApron.svg';
import leadApron from '../../assets/safety-equipment/leadApron.svg';
import clinicalJacket from '../../assets/safety-equipment/clinicalJacket.svg';
import { DropDownStyle } from '../../helpers';


const SafetyEquipmentsListContainer = styled.div`
  .row {
        &:last-child {
        margin-bottom: 1rem;
        }
        @media screen and (max-device-width:580px) and (orientation: portrait) {
            width:89%;
        }
        @media screen and (max-device-width:580px) and (orientation: landscape) {
            width:89%;
        }
    }
`;

const SafetyEquipmentsContainer = styled(Row)`
    overflow-y: auto;
    max-height:60vh;
`;

class SafetyEquipmentsList extends Component {
    state = {
        loading: false,
        safetyEquipmentDetails: { name: '', location: this.props.user.location._id, default: false },
        search: '',
        selectedSafetyEquipments: [],
        addOrEditSafetyEquipment: false,
        defaultSafetyEquipments: [],
        customSafetyEquipments: [],
        errors: { errorMessage: '' },
        safetyEquipmentLoading: false,
        isDeleteSafetyEquipmentModalOpen: false,
        locations: [],
        selectedLocation: this.props.user.location._id
    };

    componentDidMount() {
        this.getMasterData();
        this.getSafetyEquipments();
    };

    getMasterData = async () => {
        try {
            const { user } = this.props;
            const { data: { payload: locations } } = await axios.get(`/api/v2/master/locations_by_user/${user._id}`);
            this.setState({ locations });
        } catch (error) {
            console.error(error);
        }
    };

    getSafetyEquipments = async () => {
        try {
            const { user } = this.props;
            const { search, selectedLocation } = this.state;
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(`/api/v2/safetyEquipments/${selectedLocation ? selectedLocation : user.location._id}`, { params: { search } });
            const defaultSafetyEquipments = [];
            const customSafetyEquipments = [];

            if (payload.data.length) {
                payload.data.forEach((safetyEquipment) => {
                    if (safetyEquipment.default) {
                        defaultSafetyEquipments.push(safetyEquipment);
                    } else {
                        customSafetyEquipments.push(safetyEquipment);
                    };
                });
            };

            this.setState({ loading: false, defaultSafetyEquipments, customSafetyEquipments });
        } catch (error) {
            this.setState({ loading: false, defaultSafetyEquipments: [], errors: { apiError: true } });
            console.error(error);
        };
    };

    search = (value) => {
        this.setState({ search: value }, () => {
            this.getSafetyEquipments();
        });
    };

    editAndAddSafetyEquipment = async (e, safetyEquipmentDetails) => {
        e.stopPropagation();
        this.setState({ addOrEditSafetyEquipment: true, safetyEquipmentDetails });
    };

    deleteSafetyEquipment = async () => {
        try {
            const { safetyEquipmentDetails } = this.state;
            await axios.delete(`/api/v2/safetyEquipment/${safetyEquipmentDetails._id}`);
            await this.getSafetyEquipments();
            this.closePopUpModal();
            Swal.fire({
                icon: 'success',
                title: 'Safety Equipment has been deleted successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
        } catch (error) {
            if (error.response && (error.response.status === 405 || error.response.status === 404)) {
                this.setState({
                    saveOrUpdateInProgress: false,
                    errors: { apiError: true, errorMessage: error.response.data.message }
                });
                return;
            };
            this.setState({
                saveOrUpdateInProgress: false,
                errors: { apiError: true, errorMessage: 'Error at deleting safetyEquipment.' }
            });
        }
    };

    closePopUpModal = async () => {
        this.setState({
            addOrEditSafetyEquipment: false,
            safetyEquipmentDetails: {
                name: "", description: "", jobTasks: [], defaultEquipment: [], location: this.props.user.location._id
            },
            errors: {},
            saveOrUpdateInProgress: false,
            isDeleteSafetyEquipmentModalOpen: false
        });
        await this.getSafetyEquipments();
    };

    viewSafetyEquipment = (safetyEquipmentId) => {
        const { history } = this.props;
        history.push('/admin/ppe-view?safetyEquipmentId=' + safetyEquipmentId);
    };

    onLocationChange = async (value) => {
        const selectedLocation = value ? value.value : this.props.user.location._id;
        this.setState({ selectedLocation, search: '' }, () => {
            this.getSafetyEquipments();
        });
    };

    render() {
        const { safetyEquipmentDetails, search, defaultSafetyEquipments, customSafetyEquipments, loading, isDeleteSafetyEquipmentModalOpen, saveOrUpdateInProgress, addOrEditSafetyEquipment, errors, errors: { apiError, errorMessage }, selectedLocation, locations } = this.state;

        const icons = { faceShieldApron, fluidResistantGown, laserSafetyGoggles: safetyGoggles, eyewear: safetyGoggles, safetyGoggles, latexGloves, heavyDutyGloves, respiratoryMaskN95: mask, fitTestedRespiratoryMaskN95: mask, mask, customSafetyEquipment, leadApron, clinicalJacket };
        const { user } = this.props;
        return (
            <SafetyEquipmentsListContainer>
                <Row className="mx-2">
                    <Col lg='6' md='6' sm='12'>
                        <SearchInput value={search} className='pl-5 col-lg-6 col-md-12 col-sm-12' onChange={(e) => this.search(e.target.value)} type='text' placeholder='Search...' />
                    </Col>
                    <Col className='d-flex justify-content-end' lg='6' md='6' sm='12'>
                        <Select
                            className='min-w-25'
                            options={locations}
                            value={locations.find(lo => lo.value === selectedLocation)}
                            defaultValue={locations.find(lo => lo.value === selectedLocation)}
                            onChange={this.onLocationChange}
                            placeholder='Locations'
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                            isClearable={selectedLocation === user.location._id ? false : true}
                        />
                        <AddButton onClick={(e) => this.editAndAddSafetyEquipment(e)} className='ml-2 my-0'><img src={addIcon} alt="Add Icon" /> Add PPE</AddButton>
                    </Col>
                </Row>
                <SafetyEquipmentsContainer className="mt-2 mx-2">
                    {
                        loading &&
                        <div className='w-100 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                    }
                    {!loading &&
                        <>
                            <ContentHeader className='mt-0 pb-0'> Custom Safety Equipments ({customSafetyEquipments.length || 0})</ContentHeader>
                            {customSafetyEquipments.map((safetyEquipment, index) => (
                                <Col key={index} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={() => this.viewSafetyEquipment(safetyEquipment._id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={customSafetyEquipment}
                                                />
                                                <Figure.Caption className='w-100'>
                                                    <span className='d-flex justify-content-end'>
                                                        <img style={{ cursor: 'pointer' }} onClick={(e) => this.editAndAddSafetyEquipment(e, safetyEquipment)} src={pencil} className='mr-2' alt="Edit" />
                                                        <img style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); this.setState({ isDeleteSafetyEquipmentModalOpen: true, safetyEquipmentDetails: safetyEquipment }); }} src={trash} className='mr-2' alt="Delete" />
                                                    </span>
                                                    <div className="d-flex justify-content-start text-success">
                                                        <h5>
                                                            {safetyEquipment.name}
                                                        </h5>
                                                    </div>
                                                    Location:{safetyEquipment.ppeFoundAt ? safetyEquipment.ppeFoundAt : '--'}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                        </>
                    }
                    {!loading &&
                        <>
                            <ContentHeader className='mt-0 pb-0'>Default Safety Equipments ({defaultSafetyEquipments.length || 0})</ContentHeader>
                            {defaultSafetyEquipments.map((safetyEquipment, index) => (
                                <Col key={index + 'd'} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={() => this.viewSafetyEquipment(safetyEquipment._id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={icons[safetyEquipment.id] || customSafetyEquipment}
                                                />
                                                <Figure.Caption className='w-100 textAlign'>
                                                    <span className='d-flex justify-content-end'>
                                                        <img style={{ cursor: 'pointer' }} onClick={(e) => this.editAndAddSafetyEquipment(e, safetyEquipment)} src={pencil} className='mr-2' alt="Edit" />
                                                    </span>
                                                    <div className="text-success">
                                                        <h5>
                                                            {safetyEquipment.name}
                                                        </h5>
                                                    </div>
                                                    Location:{safetyEquipment.ppeFoundAt ? safetyEquipment.ppeFoundAt : '--'}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                        </>
                    }
                    {apiError && !defaultSafetyEquipments.length && !customSafetyEquipments.length &&
                        <Col>
                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                <p className='w-100 mb-0'>Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.</p>
                            </Alert>
                        </Col>
                    }
                </SafetyEquipmentsContainer>
                {
                    addOrEditSafetyEquipment && <AddEditSafetyEquipment addOrEditSafetyEquipment={addOrEditSafetyEquipment} safetyEquipment={safetyEquipmentDetails} closePopUpModal={this.closePopUpModal} user={this.props.user} />
                }
                {isDeleteSafetyEquipmentModalOpen &&
                    <Modal
                        centered
                        animation={true}
                        backdrop="static"
                        keyboard={false}
                        show={isDeleteSafetyEquipmentModalOpen}>
                        <Modal.Body>
                            <div className='text-center mt-2'>
                                <h3>Are you sure?</h3>
                                <h5 className='my-3'>Do you want to delete the Safety Equipment? This process cannot be undone.</h5>
                            </div>
                            {apiError &&
                                <Row className='mb-2'>
                                    <Col>
                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                            onClose={() => this.setState({ errors: { ...errors, apiError: false } })} dismissible>
                                            <p className='w-100 mb-0'>{errorMessage}</p>
                                        </Alert>
                                    </Col>
                                </Row>
                            }
                            <Modal.Footer className='p-0'>
                                <div className='d-flex justify-content-end'>
                                    <Button className='my-0 mr-3' type='button' onClick={() => this.closePopUpModal()}>No</Button>
                                    <WhiteButton disabled={saveOrUpdateInProgress} className='my-0' type='button' onClick={() => this.deleteSafetyEquipment()}>{saveOrUpdateInProgress ? 'Deleting...' : 'Yes'}</WhiteButton>
                                </div>
                            </Modal.Footer>
                        </Modal.Body>
                    </Modal>
                }
            </SafetyEquipmentsListContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(SafetyEquipmentsList);