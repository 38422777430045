import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Alert, Button, Col, Figure, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { CustomCard, CustomCardBody, DateSection, IconContainer, ImageContainer, NameBlockElement, OverLayContainer, StyledCard, StyledLabel, TaskBadge, TaskDetailsContainer, TaskHeading, TaskSubHeading } from './Style';
import { completeTask, updateNewHireUser } from '../../actions';

import CalenderGreen from '../../assets/images/calendar-green.svg';
import violenceIncidentForm from '../../assets/forms-icon/violenceIncidentForm.svg';
import exposureIncidentForm from '../../assets/forms-icon/exposureIncidentForm.svg';
import sharpsInjuryForm from '../../assets/forms-icon/sharpsInjuryForm.svg';
import chemicalExposureForm from '../../assets/forms-icon/chemicalExposureForm.svg';
import eval_sharps_disposal from '../../assets/forms-icon/eval_sharps_disposal.svg';
import hepatitisBVaccineConsentForm from '../../assets/forms-icon/hepatitisBVaccineConsentForm.svg';
import sharpsDisposalContainerEvaluationForm from '../../assets/forms-icon/sharpsDisposalContainerEvaluationForm.svg';
import ECRIsNeedleStickPreventionDeviceEvaluationForm from '../../assets/forms-icon/ECRIsNeedleStickPreventionDeviceEvaluationForm.svg';
import safetyDentalSyringesForm from '../../assets/forms-icon/safetyDentalSyringesForm.svg';
import SlipTripandFallForm from '../../assets/forms-icon/SlipTripandFallForm.svg';
import quarterlySafetyReportForm from '../../assets/forms-icon/quarterlySafetyReportForm.svg';
import needlestickSafetyAndPreventionAnnualRiskAssessment from '../../assets/forms-icon/needlestickSafetyAndPreventionAnnualRiskAssessment.svg';
import tbRiskAssessment from '../../assets/forms-icon/tbRiskAssessment.svg';
import uploadHealthDocumentForm from '../../assets/forms-icon/uploadHealthDocumentForm.svg';
import DocUpload from './DocUpload';
import axios from 'axios';

const icons = { violenceIncidentForm, sharpsDisposalContainerEvaluationForm, exposureIncidentForm, sharpsInjuryForm, chemicalExposureForm, eval_sharps_disposal, hepatitisBVaccineConsentForm, ECRIsNeedleStickPreventionDeviceEvaluationForm, safetyDentalSyringesForm, SlipTripandFallForm, quarterlySafetyReportForm, needlestickSafetyAndPreventionAnnualRiskAssessment, tbRiskAssessment, uploadHealthDocumentForm };


const TaskDetails = ({ users, task, setTaskDetailOpen, forms, open, afterSave }) => {
    console.log("🚀 ~ TaskDetails ~ task:", task)
    const [form, setForm] = useState({});
    const [members, setMembers] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const history = useHistory();
    const currentUser = useSelector(state => state.user.currentUser);
    console.log("🚀 ~ TaskDetails ~ currentUser:", currentUser)
    const dispatch = useDispatch();

    useEffect(() => {
        if (task.users && task.users.length) {
            const temp = users.filter((member) => task.users.includes(member.value));
            setMembers(temp);
        };

        if (task.forms && task.forms.length) {
            const tempForm = forms.find(form => task.forms.includes(form.id));
            if (tempForm) setForm(tempForm);
        };
    }, []);

    const markComplete = async () => {
        try {

            if (task.taskBehaviorType === 'task_with_form' && task.forms && task.forms[0]) {
                Swal.fire({
                    icon: 'info',
                    title: `Please complete the attached form; the task will be automatically finished afterwards`,
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                });
                return;
            } else if (task.taskBehaviorType === 'task_with_doc_upload') {
                const files = selectedFiles.filter(file => !file.inValid);
                if (files && files.length) {
                    const formData = new FormData();
                    for (const file of files) {
                        formData.append(file.name, file);
                    };
                    formData.append('name', task.name);
                    formData.append('location', currentUser.location._id);
                    formData.append('isApproved', currentUser.isAdmin ? 'true' : 'false');
                    formData.append('data', JSON.stringify(task));
                    await axios.post('/api/v2/complete_doc_task/' + task._id, formData);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: `Please select the document, and then complete the task`,
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                    return;
                }
            } else {
                if (task.id === 'newHire') {
                    // update needed
                    const data = await dispatch(updateNewHireUser(currentUser._id));
                    if (data) {
                        await dispatch(completeTask(task));
                    } else {
                        Swal.fire({
                            icon: 'info',
                            title: `As a new hire, you must complete the New Hire Training video within ten days of your start date`,
                            allowEnterKey: true,
                            allowEscapeKey: false,
                            confirmButtonColor: '#4FB973',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false
                        });
                        return;
                    };
                } else {
                    await dispatch(completeTask(task));
                };
            }
            Swal.fire({
                icon: 'success',
                title: `Task completed successfully`,
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                afterSave();
            });
        } catch (error) {
            console.log("🚀 ~ markComplete ~ error:", error)
        }
    };

    return (
        <OverLayContainer onClick={() => setTaskDetailOpen(false)} isOpen={open}>
            <TaskDetailsContainer onClick={(e) => e.stopPropagation()} isOpen={open}>
                <Button onClick={() => setTaskDetailOpen(false)} type='button' variant='light' className='text-success px-4'>
                    <strong>{'<< Back'}</strong>
                </Button>
                <div className='my-4'>
                    <TaskBadge pill>{task.frequencyName}</TaskBadge>
                    <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                </div>
                <div>
                    <TaskHeading>{task.name}</TaskHeading>
                    <TaskSubHeading>{task.instructions}</TaskSubHeading>
                </div>

                <Row>
                    <Col lg={6} mg={6} sm={12}>
                        <StyledLabel>Due Next</StyledLabel>
                        <DateSection><img src={CalenderGreen} alt="" className='mr-3' />
                            {moment(task.due_date).format('MM/DD/YYYY')}
                        </DateSection>
                    </Col>
                    <Col lg={6} mg={6} sm={12}>
                        <StyledLabel>Last Completed</StyledLabel>
                        <DateSection>
                            <img src={CalenderGreen} alt="" className='mr-3' />
                            {task.lastCompletedAt ? moment(task.lastCompletedAt).format('MM/DD/YYYY') : '- -'}
                        </DateSection>
                    </Col>
                </Row>
                {(members && members.length) ?
                    <Row className='mt-2'>
                        <Col lg={12} >
                            <StyledLabel>Assigned To</StyledLabel>
                        </Col>
                        {members.map(({ firstName, lastName, label, value }) => (
                            <Col xs={12} md={6} lg={6} key={value}>
                                <StyledCard>
                                    <NameBlockElement firstName={firstName} lastName={lastName} />
                                    <h6>{label}</h6>
                                    <Tooltip placement="bottom" className="in" id="tooltip-bottom">
                                        {label}
                                    </Tooltip>
                                </StyledCard>
                            </Col>
                        ))}
                    </Row> : null
                }
                {
                    task.taskBehaviorType === 'task_with_form' && (
                        <Row className='my-4'>
                            <Col lg={12} mg={12} sm={12}>
                                <StyledLabel>Required Forms To Complete</StyledLabel>
                            </Col>
                            <Col lg={12} mg={12} sm={12}>
                                {
                                    form && form.name ?
                                        <CustomCard onClick={() => { history.push(`/forms/view?formId=${task.forms[0]}&taskId=${task._id}`); }}>
                                            <CustomCardBody>
                                                <IconContainer>
                                                    <ImageContainer
                                                        width='56'
                                                        height='56'
                                                        src={icons[form.id]}
                                                    />
                                                    <Figure.Caption>
                                                        <div className="text-success">
                                                            <h5>
                                                                {form.name}
                                                            </h5>
                                                        </div>
                                                        <p>
                                                            {form.description}
                                                        </p>
                                                    </Figure.Caption>
                                                </IconContainer>
                                            </CustomCardBody>
                                        </CustomCard>
                                        :
                                        <Alert className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                            <p className='w-100 mb-0'>
                                                No form needs to be completed to submit this task.
                                            </p>
                                        </Alert>
                                }
                            </Col>
                        </Row>
                    )
                }
                {
                    task.taskBehaviorType === "task_with_doc_upload" && (
                        <DocUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} task={task} />
                    )
                }
                <Row className='my-4'>
                    <Col lg={12} mg={12} sm={12}>
                        <Button onClick={markComplete} size='lg' type='button' variant='success' className='w-100'><strong>Mark Complete</strong></Button>
                    </Col>
                </Row>
            </TaskDetailsContainer>
        </OverLayContainer>
    );
}

export default TaskDetails;