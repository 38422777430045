
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { ColHeader, TaskBadge, TaskBody, TaskContainer, TaskHeader, TaskInstruction, TaskListContainer, TaskTitle, TasksCard, TasksColumn, TasksColumnBody } from "./Style"
import clock from '../../assets/images/clock.svg';
import moment from "moment";
import { Spinner } from "react-bootstrap";
import TaskDetails from "./TaskDetails";

const TaskDashboard = () => {

    const currentUser = useSelector(state => state.user.currentUser);

    const [loading, setLoading] = useState(false);
    const [tasksList, setTaskList] = useState({ dueOnce: [], dueThisWeek: [], dueThisMonth: [], quarterly: [], dueThisYear: [] });
    const [taskDetailsOpen, setTaskDetailOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState({});
    const [forms, setForms] = useState({});
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getTasks();
        getMasterData();
        getFormsList();
    }, []);

    const getMasterData = async () => {
        try {
            let { data: { payload } } = await axios.get(`/api/v2/master/users_by_location/${currentUser.location._id}`);
            setUsers(payload);
        } catch (error) {
            console.log("🚀 ~ getMasterData ~ error:", error)
        }
    };

    const getTasks = async () => {
        try {
            setLoading(true);
            let { data: { payload } } = await axios.get(`/api/v2/users_tasks/${currentUser._id}/${currentUser.location._id}`);

            if (!currentUser.isAdmin) {
                payload = payload.filter(task => !task.roles.includes('admin'));
            };

            const tasksObj = {};

            tasksObj.dueToday = [];

            tasksObj.dueOnce = payload.filter(t => t.frequency === '99' && !tasksObj.dueToday.find((ta) => ta._id === t._id) && !t.lastCompletedAt);

            tasksObj.dueThisWeek = payload.filter(task => moment(task.due_date).isSame(moment(), 'week') && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== '99' && task.frequency === '0');

            tasksObj.dueThisMonth = payload.filter(task => moment(task.due_date).isSame(moment(), 'month') && !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== '99' && task.frequency === '1');
            const startOfCurrentQuarter = moment().startOf('quarter');
            const endOfCurrentQuarter = moment().endOf('quarter');
            tasksObj.quarterly = payload.filter(task => moment(task.due_date).isBetween(startOfCurrentQuarter, endOfCurrentQuarter) && !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== '99' && task.frequency === '2');

            tasksObj.dueThisYear = payload.filter(task => moment(task.due_date).isSame(moment(), 'year') && !tasksObj.dueThisMonth.map((i) => i._id).includes(task._id) && !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== 99 && task.frequency === '3');

            setTaskList(tasksObj);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("🚀 ~ file: Tasks.jsx:20 ~ getTasks ~ error:", error);

        }
    };

    const getFormsList = async () => {
        try {
            const { data: { payload: formsList } } = await axios.get('/api/v2/forms');
            setForms(formsList);
        } catch (error) {
            console.error("🚀 ~ file: Tasks.jsx:20 ~ getTasks ~ error:", error);
        }
    };

    const selectTask = (task) => {
        setSelectedTask(task);
        setTaskDetailOpen(true);
    };

    const afterSave = () => {
        getTasks();
        setSelectedTask({});
        setTaskDetailOpen(false);
    };
    return (
        <TaskContainer className="mt-3">
            <TaskHeader>
                <h2>Tasks</h2>
            </TaskHeader>
            <TaskBody>
                <TaskListContainer>
                    <TasksColumn>
                        <ColHeader>
                            <h3>One Time Basis Tasks  <span className="text-secondary ml-3">{tasksList.dueOnce.length || 0}</span></h3>
                        </ColHeader>
                        <TasksColumnBody>
                            {loading ?
                                <div className='text-center my-3'>
                                    <Spinner animation="border" variant="success" />
                                </div>
                                :
                                tasksList.dueOnce?.map((task) => (
                                    <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Regular Task' })} key={task._id}>
                                        <TaskBadge pill>Regular task</TaskBadge>
                                        <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                        <TaskTitle>{task.name}</TaskTitle>
                                        <TaskInstruction>
                                            <p>{task.instructions}</p>
                                            <p className="mt-4 mb-0"><img src={clock} alt="" className='mr-3' />{moment(task.due_date).format('MMM D')}</p>
                                        </TaskInstruction>
                                    </TasksCard>
                                ))}
                        </TasksColumnBody>
                    </TasksColumn>
                    <TasksColumn>
                        <ColHeader>
                            <h3>Weekly Tasks  <span className="text-secondary ml-3">{tasksList.dueThisWeek.length || 0}</span></h3>
                        </ColHeader>
                        <TasksColumnBody>
                            {loading ?
                                <div className='text-center my-3'>
                                    <Spinner animation="border" variant="success" />
                                </div>
                                :
                                tasksList.dueThisWeek?.map((task) => (
                                    <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Weekly Task' })} key={task._id}>
                                        <TaskBadge pill>Weekly task</TaskBadge>
                                        <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                        <TaskTitle>{task.name}</TaskTitle>
                                        <TaskInstruction>
                                            <p>{task.instructions}</p>
                                            <p className="mt-4 mb-0"><img src={clock} alt="" className='mr-3' />{moment(task.due_date).format('MMM D')}</p>
                                        </TaskInstruction>
                                    </TasksCard>
                                ))}
                        </TasksColumnBody>
                    </TasksColumn>
                    <TasksColumn>
                        <ColHeader>
                            <h3>Monthly Tasks  <span className="text-secondary ml-3">{tasksList.dueThisMonth.length || 0}</span></h3>
                        </ColHeader>
                        <TasksColumnBody>
                            {loading ?
                                <div className='text-center my-3'>
                                    <Spinner animation="border" variant="success" />
                                </div>
                                :
                                tasksList.dueThisMonth?.map((task) => (
                                    <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Monthly Task' })} key={task._id}>
                                        <TaskBadge pill>Monthly task</TaskBadge>
                                        <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                        <TaskTitle>{task.name}</TaskTitle>
                                        <TaskInstruction>
                                            <p>{task.instructions}</p>
                                            <p className="mt-4 mb-0"><img src={clock} alt="" className='mr-3' />{moment(task.due_date).format('MMM D')}</p>
                                        </TaskInstruction>
                                    </TasksCard>
                                ))}
                        </TasksColumnBody>
                    </TasksColumn>
                    <TasksColumn>
                        <ColHeader>
                            <h3>Quarterly Tasks  <span className="text-secondary ml-3">{tasksList.quarterly.length || 0}</span></h3>
                        </ColHeader>
                        <TasksColumnBody>
                            {loading ?
                                <div className='text-center my-3'>
                                    <Spinner animation="border" variant="success" />
                                </div>
                                :
                                tasksList.quarterly?.map((task) => (
                                    <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Quarterly Task' })} key={task._id}>
                                        <TaskBadge pill>Quarterly task</TaskBadge>
                                        <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                        <TaskTitle>{task.name}</TaskTitle>
                                        <TaskInstruction>
                                            <p>{task.instructions}</p>
                                            <p className="mt-4 mb-0"><img src={clock} alt="" className='mr-3' />{moment(task.due_date).format('MMM D')}</p>
                                        </TaskInstruction>
                                    </TasksCard>
                                ))}
                        </TasksColumnBody>
                    </TasksColumn>
                    <TasksColumn>
                        <ColHeader>
                            <h3>Yearly Tasks <span className="text-secondary ml-3">{tasksList.dueThisYear.length || 0}</span></h3>
                        </ColHeader>
                        <TasksColumnBody>
                            {loading ?
                                <div className='text-center my-3'>
                                    <Spinner animation="border" variant="success" />
                                </div>
                                :
                                tasksList.dueThisYear?.map((task) => (
                                    <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Yearly Task' })} key={task._id}>
                                        <TaskBadge pill>Yearly task</TaskBadge>
                                        <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                        <TaskTitle>{task.name}</TaskTitle>
                                        <TaskInstruction>
                                            <p>{task.instructions}</p>
                                            <p className="mt-4 mb-0"><img src={clock} alt="" className='mr-3' />{moment(task.due_date).format('MMM D')}</p>
                                        </TaskInstruction>
                                    </TasksCard>
                                ))}
                        </TasksColumnBody>
                    </TasksColumn>
                </TaskListContainer>
            </TaskBody>
            {taskDetailsOpen &&
                <TaskDetails users={users} afterSave={afterSave} forms={forms} setTaskDetailOpen={setTaskDetailOpen} open={taskDetailsOpen} task={selectedTask} />
            }
        </TaskContainer>
    )
}

export default TaskDashboard;