import { useState } from "react";
import axios from "axios";
import { Alert, Button, Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import styled from "styled-components"

import pencil from '../../assets/images/pencil-green.svg';
import CloseIcon from '../../assets/images/glyph-close-gray.svg';
import SaveIcon from '../../assets/images/glyph-checkmark-green.svg';
import CloseCircularIcon from '../../assets/images/records-trash-red.svg';
import AddCircularIcon from '../../assets/images/add-green.svg';
import infoGreen from '../../assets/images/info-green.svg';
import { DropDownStyle as DropDownStyleDefault, isMongoObjectId } from '../../helpers';

const CountCircle = styled.div`
    height: 25px;
    width: 25px;
    display: inline-block;
    border:1px solid #C8E8D3;
    border-radius: 50%;
    background-color: #F3F9F5;
    color: #4FB973;
    text-align: center;
    vertical-align: middle;
`;

const SingleAnswerBlock = styled.span`
    display: inline-flex;
    justify-content: space-between;
    min-width: 10vw;
    padding: 8px 16px;
    align-items: center;
    border-radius: 100px;
    background: #F9F9F9;
`;

const TableAnswerBlock = styled.div`
    table{
        border-radius: 20px;
        tr:nth-child(even) { 
            background-color: #F9F9F9; 
        } 
    }
`;

const MultiInputField = styled(Form.Control)`
    padding: 20px !important; /* Adjust the padding as needed */
    font-size: 14px !important;
    font-family: 'Hauora' !important;
    color: var(--dark-text-color) !important;
    font-weight: 600 !important;
    border-width: 0.5px 0px 0.5px 0.5px !important;
    border-style: solid !important;
    border-color: #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 100px 0px 0px 100px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;

    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        // background-color: #e2e6ea !important;
        border-color: #4fb973 !important;
    };
`;

const SingleInputField = styled(Form.Control)`
    padding: 20px !important; /* Adjust the padding as needed */
    font-size: 14px !important;
    font-family: 'Hauora' !important;
    color: var(--dark-text-color) !important;
    font-weight: 600 !important;
    border: 0.5px solid #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 100px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;
    
    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: #4fb973 !important;
    };
`;

const TableInputField = styled.input`
    padding: 8px !important; /* Adjust the padding as needed */
    font-size: 14px !important;
    font-family: 'Hauora' !important;
    color: var(--dark-text-color) !important;
    font-weight: 600 !important;
    border: 0.5px solid #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 5px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;
    width: 100%;

    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: #4fb973 !important;
    };
`;

const RemoveButton = styled(Button)`
    background-color:#FFFFFF !important;
    font-family: 'Hauora' !important;
    border: 0.5px solid #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 0px 100px 100px 0px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;

    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        background-color: #e2e6ea !important;
        border-color: #4fb973 !important;
    };
`;

const AddButton = styled.button`
    width: 100%;
    padding: 5px 16px;
    align-items: center;
    border-radius: ${(props) => (props.table ? '5px' : '100px')};
    border: none;
    background: #f9f9f961;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    &:hover {
        background-color: #e2e6ea;
    }
`;

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

const UserSelectContainer = styled.div`
width: 25vw;
.min-25vw {
    min-width: 25vw !important;
}
`;

const SectionHeading = styled.p`
    color: #343747;
    font-family: "Haus Neue";
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D3D6DD;
`;

const AddLabelText = styled.p`
    font-size: 1.1rem;
    font-weight: 600 !important;
    cursor: pointer;
    color: #42A764;
    `;

const updateLibraryData = async (libraryData) => {
    try {
        const { data } = await axios.put(`/api/v1/updateLibraryData`, libraryData);
        return data;
    } catch (error) {
        throw error;
    }
};

const DropDownStyle = {
    option: (provided, { isDisabled, isSelected, isFocused }) => ({
        ...provided,
        backgroundColor: isDisabled ? null : isFocused ? '#4fb973' : null,
        color: isFocused ? 'white' : 'black'
    }),
    control: (provided) => ({
        ...provided,
        'boxShadow': 'inset 0 1px 1px 0 rgba(0, 0, 0, 0.16)',
        'borderColor': '#b9bcca',
        'borderRadius': '100px',
        'padding': '2px',
        ':hover': {
            'borderColor': '#4fb973'
        },
        ':active': {
            'borderColor': '#4fb973'
        }
    })
};

export const SingleAnswerTask = ({ answerData, user, locationId, getLibData }) => {

    const [answer, setAnswer] = useState(answerData.answer);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.type,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer || !answer.trim()) return setError('Required');
            setError('');
            setLoading(true);
            await updateLibraryData({ libraryData, locationId: locationId });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setLoading(false);
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
        }
    };

    return (
        <>
            {edit ?
                <Row style={{ width: "35vw", alignItems: 'center' }} className="d-inline-flex justify-content-between mx-2 mt-2">
                    <Col sm='12' lg='6'>
                        <SingleInputField disabled={loading} type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} />
                    </Col>
                    <Col sm='12' lg='6'>
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Col>
                    {error && <Col sm='12' lg='12' md='12'> <Alert variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert></Col>}
                </Row>
                :
                <SingleAnswerBlock>
                    <strong>{answerData.answer}</strong>
                    {!answerData.editOff &&
                        <img onClick={() => { setEdit(true); setAnswer(answerData.answer) }} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                    }
                </SingleAnswerBlock>
            }
        </>
    )
};

export const MultiUserSelectTask = ({ answerData, user, locationId, getLibData, usersList }) => {
    const [answer, setAnswer] = useState(answerData.answer);
    const [users, setUsers] = useState(usersList);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.type,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer) return setError('Required');
            if (!answer.length) return setError('Required');
            let tempError = false;
            answer.map(ans => {
                if (ans && ans.trim()) {
                    return ans.trim();
                } else {
                    setError('Fill all fields');
                    tempError = true;
                    return ans;
                };
            });
            if (tempError) return;
            setError('');
            if (JSON.stringify(answerData.answer) === JSON.stringify(answer)) return setEdit(false);

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: locationId });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onInputChange = (e) => {

        if (e.target.value.trim() && e.key === 'Enter') {
            const check = users.some(u => u.value === e.target.value.trim());
            if (!check) {
                e.preventDefault();
                setUsers([...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }]);
                setAnswer([...answer, e.target.value.trim()]);
            };
            e.target.value = '';
        };
    };

    const onMultiInputChange = (value) => {
        setAnswer(value);
    };

    const onEdit = () => {
        const otherOptions = users.filter(u => !u.other) || [];
        answer.forEach(op => {
            !isMongoObjectId(op) && otherOptions.push({ label: op, value: op, other: true });
        });
        setUsers(otherOptions);
        setEdit(true);
    };

    const getAnswer = () => answer.map(op => isMongoObjectId(op) ? users.find(u => u.value === op)?.label : op);

    return (
        <>
            {edit ?
                <UserSelectContainer className="mx-2">
                    <div className="d-flex justify-content-end my-2">
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                    <div >
                        <div className='d-flex'>
                            <Select
                                name="multiUserSelect"
                                value={users.filter(u => answer.includes(u.value))}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                options={users}
                                onKeyDown={onInputChange}
                                isMulti={true}
                                className='mx-1 min-25vw'
                                onChange={(e) => onMultiInputChange(e.map(u => u.value))}
                            />
                            <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                                    </Tooltip>
                                }>
                                <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                            </OverlayTrigger>
                        </div>
                        {error && <Alert className="mt-2" variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}
                    </div>
                </UserSelectContainer>
                :
                <SingleAnswerBlock>
                    <strong>{getAnswer().toString()}</strong>
                    <img onClick={() => onEdit()} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                </SingleAnswerBlock>
            }
        </>
    )
};

export const SingleUserSelectTask = ({ answerData, user, locationId, getLibData, usersList }) => {
    const [answer, setAnswer] = useState(answerData.answer);
    const [users, setUsers] = useState(usersList);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.type,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer) return setError('Required');
            if (!answer.length) return setError('Required');

            setError('');
            if (answerData.answer === answer) return setEdit(false);

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: locationId });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onInputChange = (e) => {

        if (e.target.value.trim() && e.key === 'Enter') {
            const check = users.some(u => u.value === e.target.value.trim());
            if (!check) {
                e.preventDefault();
                setUsers([...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }]);
                setAnswer(e.target.value.trim());
            };
            e.target.value = '';
        };
    };

    const onSingleInputChange = (value) => {
        setAnswer(value);
    };

    const onEdit = () => {
        const otherOptions = users.filter(u => !u.other) || [];
        !isMongoObjectId(answer) && otherOptions.push({ label: answer, value: answer, other: true });
        setUsers(otherOptions);
        setEdit(true);
    };

    const getAnswer = () => isMongoObjectId(answer) ? users.find(u => u.value === answer)?.label : answer;

    return (
        <>
            {edit ?
                <UserSelectContainer className="mx-2">
                    <div className="d-flex justify-content-end my-2">
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                    <div >
                        <div className='d-flex'>
                            <Select
                                name="singleUserSelect"
                                value={users.filter(u => u.value === answer)}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                options={users}
                                onKeyDown={onInputChange}
                                isMulti={false}
                                className='mx-1 min-25vw'
                                onChange={(e) => onSingleInputChange(e.value)}
                            />
                            <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                                    </Tooltip>
                                }>
                                <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                            </OverlayTrigger>
                        </div>
                        {error && <Alert className="mt-2" variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}
                    </div>
                </UserSelectContainer>
                :
                <SingleAnswerBlock>
                    <strong>{getAnswer().toString()}</strong>
                    <img onClick={() => onEdit()} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                </SingleAnswerBlock>
            }
        </>
    )
};

export const MultiAnswerTask = ({ answerData, user, locationId, getLibData }) => {
    const [answer, setAnswer] = useState(answerData.answer);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.type,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer) return setError('Required');
            let tempError = false;
            answer.map(ans => {
                if (ans && ans.trim()) {
                    return ans.trim();
                } else {
                    setError('Fill all fields');
                    tempError = true;
                    return ans;
                };
            });
            if (tempError) return;
            setError('');
            if (JSON.stringify(answerData.answer) === JSON.stringify(answer)) return setEdit(false);;

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: locationId });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onChange = (index, value) => {
        const tempAnswer = [...answer];
        tempAnswer[index] = value;
        setAnswer(tempAnswer);
    };

    const addRow = () => {
        const tempAnswer = [...answer];
        tempAnswer.push("");
        setAnswer(tempAnswer);
    };

    const removeRow = (e, index) => {
        const tempAnswer = [...answer];
        tempAnswer.splice(index, 1);
        setAnswer(tempAnswer);
    };

    return (
        <>
            {edit ?
                <div style={{ width: '25vw' }} className="mx-2">
                    <div className="d-flex justify-content-end my-2">
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                    <div style={{ width: '25vw' }}>
                        {answer.map((ans, index) =>
                            <InputGroup key={index} className="d-flex mt-2">
                                <MultiInputField disabled={loading} type="text" value={ans} onChange={(e) => onChange(index, e.target.value)} />
                                <RemoveButton disabled={answer.length < 2 || loading} variant="light" onClick={(e) => removeRow(e, index)}> <img src={CloseCircularIcon} alt="" /></RemoveButton>
                            </InputGroup>
                        )}

                        {error && <Alert variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}

                        <AddButton disabled={loading} onClick={addRow} className="mt-3"><img src={AddCircularIcon} alt="" className="cursor-pointer mr-1" /> Add row</AddButton>
                    </div>
                </div>
                :
                <SingleAnswerBlock>
                    <strong>{answerData.answer.toString()}</strong>
                    <img onClick={() => { setEdit(true); setAnswer(answerData.answer) }} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                </SingleAnswerBlock>
            }
        </>
    )
};

export const TableAnswerTask = ({ answerData, headers, getLibData, user, locationId, usersList }) => {

    const [answer, setAnswer] = useState({ old: JSON.parse(JSON.stringify(answerData.answer)), new: [] });
    const [edit, setEdit] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        e.preventDefault();
        try {
            const form = e.currentTarget;
            if (!form.checkValidity()) {
                e.preventDefault();
                e.stopPropagation();
                setValidated(true);
                return
            };
            const libraryData = {
                answer: [...answer.new, ...answer.old],
                associatedVariable: answerData.associatedVariable,
                type: answerData.type,
                stepNumber: answerData.stepNumber,
                question: answerData.question,
                isTaskForThisStep: answerData.isTaskForThisStep,
                taskDetails: answerData.isTaskForThisStep ? answerData.taskDetails : {},
                tableLayout: answerData.tableLayout || {}
            };
            setError('');
            if (JSON.stringify(answerData.answer) === JSON.stringify(answer)) return setEdit(false);;

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: locationId });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onChange = (index, header, key, value) => {
        const tempAnswer = { ...answer };
        tempAnswer[key][index][header] = value;
        setAnswer(tempAnswer);
    };

    const addRow = () => {
        const tempAnswer = { ...answer };
        tempAnswer.new.push({});
        setAnswer(tempAnswer);
    };

    const removeRow = (e, key, index) => {
        const tempAnswer = { ...answer };
        tempAnswer[key].splice(index, 1);
        setAnswer(tempAnswer);
    };

    const checkDisable = () => {
        const len = (answer.new.length || 0) + (answer.old.length || 0);
        if (len) return false;
        return true;
    };

    return (
        <>
            {edit ?
                <TableAnswerBlock className="my-2">
                    <Form noValidate validated={validated} onSubmit={saveAnswer}>
                        <SectionHeading>
                            <span>
                                {(answerData.taskDetails && answerData.taskDetails.task_section) ? answerData.taskDetails.task_section : answerData.question} <CountCircle>{answerData.answer.length}</CountCircle>
                            </span>
                            <div className="text-right mb-2">
                                <Button disabled={loading} onClick={() => { setEdit(false); setError(''); setValidated(false) }} variant="light" className="text-secondary mx-1">
                                    <img src={CloseIcon} alt="" className="mr-2" />
                                    Cancel
                                </Button>
                                <Button disabled={loading || checkDisable()} type="submit" variant="light" className="text-center text-success mx-1">
                                    <img src={SaveIcon} alt="" className="mr-2" />
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </SectionHeading>
                        {answer.old && answer.old.length ?
                            <Table borderless={true} responsive="md" className="mt-3">
                                <thead>
                                    <tr>
                                        {
                                            headers.map((header, a) =>
                                                <th className="w-25" key={a + 'old_tableHead'}>
                                                    {header}
                                                </th>
                                            )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {answer.old.map((ans, b) =>
                                        <tr key={b + 'old_tableRow'}>
                                            {headers.map((header, c) =>
                                                <td className="w-25 align-middle" key={c + b + 'old_tableData'}>
                                                    {
                                                        header === 'Frequency'
                                                            ?
                                                            answerData.taskDetails.frequency
                                                            :
                                                            (
                                                                answerData.tableLayout.userSelectColumns && answerData.tableLayout.userSelectColumns.includes(header) ?
                                                                    <div>
                                                                        <input type="text" value={ans[header] || ''} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }} />
                                                                        <Select
                                                                            name={header}
                                                                            value={usersList.find(u => u.value === ans[header]) || null}
                                                                            menuPosition="fixed"
                                                                            styles={DropDownStyleDefault}
                                                                            options={usersList}
                                                                            onChange={(e) => onChange(b, header, 'old', e.value)}
                                                                        />
                                                                        <ErrorContainer type="invalid">Required</ErrorContainer>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <TableInputField required={header === 'Nickname' ? false : true} name={header + (c + b)} onChange={(e) => onChange(b, header, 'old', e.target.value)} value={ans[header]} />
                                                                        <ErrorContainer type="invalid">Required</ErrorContainer>
                                                                    </div>
                                                            )
                                                    }

                                                </td>
                                            )}
                                            <td style={{ width: '5%' }}>
                                                <Button type="button" disabled={answer.length < 2 || loading} variant="light" onClick={(e) => removeRow(e, 'old', b)}>
                                                    <img src={CloseCircularIcon} alt="" />
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            :
                            null
                        }
                        {answer.new && answer.new.length ?
                            <>
                                <SectionHeading>
                                    Add {(answerData.taskDetails && answerData.taskDetails.task_section) ? answerData.taskDetails.task_section : answerData.question}
                                </SectionHeading>
                                <Table borderless={true} responsive="md" className="mt-3">
                                    <thead>
                                        <tr>
                                            {
                                                headers.map((header, a) =>
                                                    <th className="w-25" key={a + 'new_tableHead'}>
                                                        {header}
                                                    </th>
                                                )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {answer.new.map((ans, b) =>
                                            <tr key={b + 'new_tableRow'}>
                                                {headers.map((header, c) =>
                                                    <td className="w-25 align-middle" key={c + b + 'new_tableData'}>
                                                        {
                                                            header === 'Frequency'
                                                                ?
                                                                answerData.taskDetails.frequency
                                                                :
                                                                (
                                                                    answerData.tableLayout.userSelectColumns && answerData.tableLayout.userSelectColumns.includes(header) ?
                                                                        <div>
                                                                            <input type="text" value={ans[header] || ''} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }} />
                                                                            <Select
                                                                                name={header}
                                                                                value={usersList.find(u => u.value === ans[header]) || null}
                                                                                menuPosition="fixed"
                                                                                styles={DropDownStyleDefault}
                                                                                options={usersList}
                                                                                onChange={(e) => onChange(b, header, 'new', e.value)}
                                                                            />
                                                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <TableInputField required={true} name={header + (c + b)} onChange={(e) => onChange(b, header, 'new', e.target.value)} value={ans[header]} />
                                                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                                                        </div>
                                                                )
                                                        }

                                                    </td>
                                                )}
                                                <td style={{ width: '5%' }}>
                                                    <Button type="button" disabled={answer.length < 2 || loading} variant="light" onClick={(e) => removeRow(e, 'new', b)}>
                                                        <img src={CloseCircularIcon} alt="" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                        {error &&
                                            <tr>
                                                <td colSpan={headers.length + 1}>
                                                    <Alert variant="danger" style={{ maxWidth: '100%' }} >
                                                        {error}
                                                    </Alert>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </>
                            :
                            null
                        }
                        <AddLabelText disabled={loading} onClick={addRow} className="mb-4">
                            <img src={AddCircularIcon} alt="" className="cursor-pointer mr-1" /> Add  {(answerData.taskDetails && answerData.taskDetails.task_section) ? answerData.taskDetails.task_section : answerData.question}
                        </AddLabelText>
                    </Form>
                </TableAnswerBlock>
                :
                <TableAnswerBlock className="my-2">
                    <div className="text-right">
                        <SectionHeading>
                            <span>
                                {(answerData.taskDetails && answerData.taskDetails.task_section) ? answerData.taskDetails.task_section : answerData.question} <CountCircle>{answerData.answer.length}</CountCircle>
                            </span>
                            <strong onClick={() => { setAnswer({ old: JSON.parse(JSON.stringify(answerData.answer)), new: [] }); setEdit(true) }} className="text-success cursor-pointer">
                                <img src={pencil} alt="Edit" /> Edit
                            </strong>
                        </SectionHeading>
                    </div>
                    <Table borderless={true} responsive="md" className="mt-3">
                        <thead>
                            <tr style={{ borderBottom: '1px solid rgb(240 240 240)' }}>
                                {
                                    headers.map((header, a) =>
                                        <th className="w-25" key={a + 'tableHead'}>
                                            {header}
                                        </th>
                                    )}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                answerData.answer.map((ans, b) =>
                                    <tr key={b + 'tableRow'} style={{ borderBottom: '1px solid rgb(240 240 240)' }}>
                                        {headers.map((header, c) =>
                                            <td className="w-25" key={c + b + 'tableData'}>
                                                {
                                                    header === 'Frequency'
                                                        ?
                                                        answerData.taskDetails.frequency :
                                                        (answerData.tableLayout.userSelectColumns && answerData.tableLayout.userSelectColumns.includes(header) ? usersList.find(u => u.value === ans[header])?.label || '--' : ans[header] || '--')
                                                }
                                            </td>
                                        )}
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </TableAnswerBlock>
            }
        </>
    )
};