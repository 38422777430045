import React, { Component } from 'react';
import { connect } from "react-redux";
import { Alert, Col, Form, OverlayTrigger, ProgressBar, Spinner, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Select from "react-select";
import styled from "styled-components";

import { Card } from '../Card';
import libraryIcon from '../../assets/images/sidebar-library-normal.svg';
import { Input } from '../Input';
import { Button } from '../Button';

import infoGreen from '../../assets/images/info-green.svg';
import { DropDownStyle, isMongoObjectId } from '../../helpers';

const AdditionalStepsContainer = styled(Card)`
    min-width:50%;
    max-width: 80%;
`;

const MainContainer = styled.div`
    max-width:100%
    overflow-x: hidden;
    padding:15px;  
    @media (max-width:480px) {
     width:100%;
     height:100%;
     overflow-x: auto;
    }  
    @media only screen and (max-width:1024px) and (min-width: 481px){
      width:100%;
      height:100%;
      overflow-x: auto;
    }  
`;

const HeadersContainer = styled.div`
    font-weight:700 !important;
    color:black;
    display:flex;
    margin-bottom:1rem;
`;

const QuestionAnswerBlock = styled(Form)`

`;

const QuestionLabel = styled.label`
    font-size:16px;
    font-weight:500;
`;
const AnswerBLock = styled.div`
    i{
        font-size:16px;
    }
`;
const FooterSection = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:1rem;
    width:100%;
`;

const ProgressBarContainer = styled.div`
    width:60%;
    margin-top:0.6rem;
`;

const ProgressLabel = styled.p`
    font-size:16px;
`;

const RadioInput = styled.input`
    accent-color: green;
    height:20px;
    width:20px;
    margin-right: 0.5rem;
    cursor: pointer;
`;

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

class AdditionalLibrarySetup extends Component {
    state = {
        loading: true,
        additionalLibrarySteps: [],
        currentStepData: {},
        errors: {},
        formData: {
            singleInputAns: '',
            multipleInputAns: [''],
            tableAns: [{}],
            booleanAns: undefined
        },
        currentStep: 0,
        totalSteps: 0,
        saveInProgress: false,
        validated: false,
        users: []
    };

    componentDidMount() {
        this.getAdditionalLibrarySteps();
        this.getMasterData();
        const { user, history } = this.props;
        if (!user.location.additionalStepsAvailable) history.goBack();
    };


    getMasterData = async () => {
        try {
            const { user: { location } } = this.props;
            const { data: { payload: users } } = await axios.get(`/api/v2/master/users_by_location/${location._id}`);
            this.setState({ users });
        } catch (error) {
            console.error(error);
        }
    };

    getAdditionalLibrarySteps = async () => {
        try {
            this.setState({ loading: true });
            const { user } = this.props;
            const { data: { payload } } = await axios.get(`/api/v2/additionalLibrarySteps/${user.location._id}`);
            const { libraryData } = await this.getLibData();
            let currentStepData = payload[0];
            let currentStep = 0;
            let breakLoop = false;
            payload.forEach((step, index) => {
                if (!breakLoop && !libraryData.find((libData) => libData.associatedVariable === step.associatedVariable)) {
                    currentStepData = step;
                    currentStep = index;
                    breakLoop = true;
                };
            });
            if (currentStepData) this.getStepAnswer(currentStepData);
            this.setState({ loading: false, additionalLibrarySteps: payload, totalSteps: payload.length, currentStepData, currentStep });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true } });
            console.error(error);
        }
    };

    addRow = (type, el) => {
        const { formData } = this.state;
        formData[type].push(el);
        this.setState({ formData });
    };

    removeRow = (type, index) => {
        const { formData } = this.state;
        formData[type].splice(index, 1)
        this.setState({ formData });
    };

    onTableInputChange = (rowIndex, colName, value) => {
        const { formData } = this.state;
        formData.tableAns[rowIndex][colName] = value;
        this.setState({ formData });
    };

    onMultiInputChange = (index, value) => {
        const { formData } = this.state;
        if (index === 'select') {
            formData.multipleInputAns = value;
        } else {
            formData.multipleInputAns[index] = value;
        };
        this.setState({ formData });
    };

    onSingleInputChange = (value) => {
        const { formData } = this.state;
        formData.singleInputAns = value;
        this.setState({ formData });
    };

    onBooleanInputChange = (value) => {
        const { formData } = this.state;
        formData.booleanAns = value;
        this.setState({ formData });
    };

    onNextClick = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ validated: true });
        } else {
            this.setState({ saveInProgress: true });
            await this.saveOrUpdateStep();
            const { currentStep, additionalLibrarySteps } = this.state;
            if ((additionalLibrarySteps.length - 1) !== currentStep) {
                await this.getStepAnswer(additionalLibrarySteps[currentStep + 1]);
                this.setState({ currentStep: currentStep + 1, currentStepData: additionalLibrarySteps[currentStep + 1], saveInProgress: false });
            } else {
                this.props.history.goBack();
            }
            this.setState({ validated: false });
        }
    };

    onBackClick = async (e) => {
        e.preventDefault();
        const { currentStep, additionalLibrarySteps } = this.state;
        await this.getStepAnswer(additionalLibrarySteps[currentStep - 1]);
        this.setState({ currentStep: currentStep - 1, currentStepData: additionalLibrarySteps[currentStep - 1] });
    };

    saveOrUpdateStep = async () => {
        try {
            let { formData, currentStep, additionalLibrarySteps, currentStepData, currentStepData: { type, associatedVariable, stepNumber } } = this.state;
            console.log("🚀 ~ AdditionalLibrarySetup ~ saveOrUpdateStep= ~ currentStepData:", currentStepData)
            const { user } = this.props;
            const { textContent } = document.getElementById('questionElement');
            if (type === 'multipleUserSelect') type = 'multipleInput';
            if (type === 'singleUserSelect') type = 'singleInput';

            const libData = {
                question: textContent,
                answer: formData[`${type}Ans`],
                associatedVariable,
                type,
                stepNumber,
                isTaskForThisStep: currentStepData.isTaskForThisStep,
                taskDetails: currentStepData.isTaskForThisStep ? currentStepData.taskDetails : {},
                tableLayout: currentStepData.tableLayout || {},
                isLast: (additionalLibrarySteps.length - 1) === currentStep ? true : false
            };
            console.log("🚀 ~ AdditionalLibrarySetup ~ saveOrUpdateStep= ~ libData:", libData)
            const { data: { payload } } = await axios.post(`/api/v2/additionalLibrarySteps/${user.location._id}`, libData);
            return payload;
        } catch (error) {
            console.error(error);
            this.setState({ saveInProgress: false, errors: { apiError: true } });
            return false;
        }
    };

    getStepAnswer = async ({ associatedVariable }) => {
        const { libraryData } = await this.getLibData();
        let { formData, users } = this.state;
        const answer = libraryData.find((step) => step.associatedVariable === associatedVariable);
        if (answer) {
            if (answer.type === 'multipleUserSelect') {
                const otherOptions = users.filter(u => !u.other) || [];
                formData.multipleInputAns.forEach(op => {
                    op && !isMongoObjectId(op) && otherOptions.push({ label: op, value: op, other: true });
                });
                this.setState({ users: otherOptions });
            } else if (answer.type === 'singleUserSelect') {
                const otherOptions = users.filter(u => !u.other) || [];
                !isMongoObjectId(formData.singleAns) && otherOptions.push({ label: formData.singleAns, value: formData.singleAns, other: true });
                this.setState({ users: otherOptions });
            } else {
                formData[`${answer.type}Ans`] = answer.answer;
            };
        } else {
            formData = {
                singleInputAns: '',
                multipleInputAns: [''],
                tableAns: [{}],
                booleanAns: undefined
            };
        };
        this.setState({ formData });
    };

    getLibData = async () => {
        try {
            const { user } = this.props;
            const { data: { payload } } = await axios.get(`/api/v2/libraryData/${user.location._id}`);
            return payload;
        } catch (error) {
            console.error('error getting library data', error);
            return false;
        }
    };

    onInputChange = (e, type) => {
        if (e.target.value.trim() && e.key === 'Enter') {
            const { users, formData } = this.state;
            const check = users.some(u => u.value === e.target.value.trim());
            if (!check) {
                e.preventDefault();
                if (type === 'multi') {
                    this.setState({ users: [...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }], formData: { ...formData, multipleInputAns: [...formData.multipleInputAns, e.target.value.trim()] } });
                } else {
                    this.setState({ users: [...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }], formData: { ...formData, singleAns: e.target.value.trim() } });
                }
            };
            e.target.value = '';
        };
    };

    getColWidth = (col_count) => (90 / col_count + '%');

    render() {
        const { validated, formData: { singleInputAns, multipleInputAns, tableAns, booleanAns }, additionalLibrarySteps, currentStep, totalSteps, loading, currentStepData, saveInProgress, users, errors: { apiError } } = this.state;

        return (
            <AdditionalStepsContainer>
                <MainContainer>
                    <HeadersContainer>
                        <img src={libraryIcon} alt="Icon" className='mr-3' />
                        <h2>Additional Library Setup</h2>
                    </HeadersContainer>
                    {(apiError && !loading) ?
                        <Col>
                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                <p className='w-100 mb-0'>Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.</p>
                            </Alert>
                        </Col>
                        :
                        (!loading && currentStepData) &&
                        <QuestionAnswerBlock noValidate validated={validated} onSubmit={this.onNextClick}>
                            <QuestionLabel id='questionElement'>
                                {documentToReactComponents(currentStepData.descriptionBody)}
                            </QuestionLabel>
                            {/* Single Input */}
                            {
                                currentStepData.type === 'singleInput' &&
                                <AnswerBLock>
                                    <Input
                                        required
                                        value={singleInputAns || ''}
                                        className='col-lg-6 col-md-12 col-sm-12'
                                        type='text'
                                        onChange={(e) => this.onSingleInputChange(e.target.value)}
                                    />
                                    <ErrorContainer type="invalid">Required</ErrorContainer>
                                </AnswerBLock>
                            }
                            {/* Boolean Input  */}
                            {
                                currentStepData.type === 'boolean' &&
                                <>
                                    <AnswerBLock className='d-flex'>
                                        <div className='d-flex mr-4'>
                                            <RadioInput
                                                defaultChecked={booleanAns && booleanAns !== undefined}
                                                name='booleanInput'
                                                type='radio'
                                                onChange={(e) => this.onBooleanInputChange(true)}
                                                required
                                            />
                                            <h5>Yes</h5>
                                        </div>
                                        <div className='d-flex'>
                                            <RadioInput
                                                defaultChecked={!booleanAns && booleanAns !== undefined}
                                                type='radio'
                                                name='booleanInput'
                                                onChange={(e) => this.onBooleanInputChange(false)}
                                                required
                                            />
                                            <h5>No</h5>
                                        </div>
                                    </AnswerBLock>
                                    {(booleanAns === undefined && validated) && <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p>}
                                </>
                            }
                            {/* Multiple Input */}
                            {
                                currentStepData.type === "multipleInput" &&
                                <AnswerBLock>
                                    {multipleInputAns.map((ans, index) => (
                                        <div key={index}>
                                            <Input
                                                required
                                                value={ans || ''}
                                                className='col-lg-6 col-md-12 col-sm-12'
                                                type='text'
                                                onChange={(e) => this.onMultiInputChange(index, e.target.value)}
                                            />
                                            {((multipleInputAns.length - 1) === index) && <i onClick={() => this.addRow('multipleInputAns', '')} className="fa fa-plus-circle text-success ml-2" />}
                                            {(multipleInputAns.length > 1) && <i onClick={() => this.removeRow('multipleInputAns', index)} className="fa fa-times-circle text-danger ml-2" />}
                                            <br />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                        </div>
                                    ))}
                                </AnswerBLock>
                            }
                            {
                                currentStepData.type === 'singleUserSelect' &&
                                <div>
                                    <div className='d-flex'>
                                        <Select
                                            name={currentStepData.associatedVariable}
                                            value={users.find(u => u.value === singleInputAns)}
                                            menuPosition="fixed"
                                            styles={DropDownStyle}
                                            options={users}
                                            onKeyDown={this.onInputChange}
                                            className='col-lg-6 col-md-12 col-sm-12 mx-1 p-0'
                                            onChange={(e) => this.onSingleInputChange(e.value)}
                                        />
                                        <OverlayTrigger
                                            key='bottom'
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id={`tooltip-bottom`}>
                                                    <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                                                </Tooltip>
                                            }>

                                            <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                                        </OverlayTrigger>
                                    </div>
                                    <input type="text" value={singleInputAns} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                                    <ErrorContainer type="invalid">Required</ErrorContainer>
                                </div>
                            }
                            {
                                currentStepData.type === "multipleUserSelect" &&
                                <AnswerBLock>
                                    {console.log(multipleInputAns)}
                                    <div className='d-flex'>
                                        <Select
                                            name={currentStepData.associatedVariable}
                                            value={users.filter(u => multipleInputAns.includes(u.value))}
                                            menuPosition="fixed"
                                            styles={DropDownStyle}
                                            options={users}
                                            onKeyDown={this.onInputChange}
                                            isMulti={true}
                                            className='col-lg-6 col-md-12 col-sm-12 mx-1 p-0'
                                            onChange={(e) => { this.onMultiInputChange('select', e.map(u => u.value)) }}
                                        />
                                        <OverlayTrigger
                                            key='bottom'
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id={`tooltip-bottom`}>
                                                    <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                                                </Tooltip>
                                            }>
                                            <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                                        </OverlayTrigger>
                                    </div>
                                    <input type="text" value={multipleInputAns.toString()} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                                    <ErrorContainer type="invalid">Required</ErrorContainer>
                                </AnswerBLock>}
                            {/* Table Input  */}
                            {
                                currentStepData.type === "table" &&
                                <AnswerBLock>
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    {currentStepData.tableLayout.columns.map((columnName, index) =>
                                                        <th style={{ width: this.getColWidth(currentStepData.tableLayout.columns.length) }} key={index}>{columnName}</th>
                                                    )}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableAns.map((ans, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {
                                                                currentStepData.tableLayout.columns.map((columnName, colIndex) =>
                                                                    <td style={{ width: this.getColWidth(currentStepData.tableLayout.columns.length) }} key={colIndex}>
                                                                        {
                                                                            currentStepData.tableLayout.userSelectColumns && currentStepData.tableLayout.userSelectColumns.includes(columnName) ?
                                                                                <div>
                                                                                    <input type="text" value={ans[columnName] || ''} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }} />
                                                                                    <Select
                                                                                        name={columnName}
                                                                                        value={users.find(u => u.value === ans[columnName])}
                                                                                        menuPosition="fixed"
                                                                                        styles={DropDownStyle}
                                                                                        options={users}
                                                                                        onChange={(e) => this.onTableInputChange(rowIndex, columnName, e.value)}
                                                                                    />
                                                                                    <ErrorContainer type="invalid">Required</ErrorContainer>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <Input
                                                                                        id={colIndex === rowIndex ? '0' : '1'}
                                                                                        type="text"
                                                                                        name={columnName}
                                                                                        value={ans[columnName] || ''}
                                                                                        onChange={(e) => this.onTableInputChange(rowIndex, columnName, e.target.value)}
                                                                                        required
                                                                                    />
                                                                                    <ErrorContainer type="invalid">Required</ErrorContainer>
                                                                                </div>
                                                                        }
                                                                    </td>
                                                                )}
                                                            <td>
                                                                {
                                                                    ((tableAns.length - 1) === rowIndex) &&
                                                                    <i onClick={() => this.addRow('tableAns', {})} className="fa fa-plus-circle text-success ml-2" />
                                                                }
                                                                {
                                                                    (tableAns.length > 1) &&
                                                                    <i onClick={() => this.removeRow('tableAns', rowIndex)} className="fa fa-times-circle text-danger ml-2" />
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </AnswerBLock>
                            }
                            <FooterSection>
                                <Button disabled={currentStep === 0} onClick={this.onBackClick} className='m-0' width='80' type='button'>{'<< Back'}</Button>
                                <ProgressBarContainer className='mx-3'>
                                    <ProgressBar variant="success" now={currentStep + 1} max={totalSteps} key={1} />
                                </ProgressBarContainer>
                                <ProgressLabel className='mr-2'>{currentStep + 1} of {totalSteps}</ProgressLabel>
                                <Button disabled={saveInProgress} className='m-0' width='80' type='submit'>{saveInProgress ? 'Loading...' : (additionalLibrarySteps.length - 1) === currentStep ? 'Save' : 'Next >>'}</Button>
                            </FooterSection>
                        </QuestionAnswerBlock>
                    }
                    {
                        loading &&
                        <div className='w-100 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                    }
                </MainContainer>
            </AdditionalStepsContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(AdditionalLibrarySetup);