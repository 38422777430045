import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import eyeglass from '../../assets/images/glyph-search-gray.svg';

export const LibraryViewContainer = styled.div`
    margin: 6.5rem 0.5rem 0rem 1.5rem !important;
    width: 100%;
    align-self: flex-start;
    align-items: flex-start;
    padding: 20px;
    border-radius: 8px;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border: 1px solid  #b9b7b7!important;

    .w-200px {
    width:200px;
    @media screen and (max-device-width: 560px) {
        width: 100%;
        margin-bottom: 1rem;
    }
    }
    .w-5{
    width:5% !important;
    }
    .w-10{
    width:10% !important;
    }
    .w-15{
    width:15% !important;
    }
    .w-20{
    width:20% !important;
    }
    .w-25{
    width:25% !important;
    }
`;

export const SidePanel = styled(Col)`
    max-height: 80vh;
    overflow-y: auto;
    @media (max-width: 991px) {
        padding:0 !important;
        max-height: unset !important;
    }
`;

export const AccordionCard = styled.div`
    border-radius: 6px;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border: 1px solid  #b9b7b7!important;
    // ${(props) => (!props.disabled ? "&:hover { border: 1.5px solid #4FB973 !important;box-shadow: 0px 0px 2px 0px '#4FB973' !important; }" : '')};
    padding: 15px;
    margin-bottom: 15px;
`;

export const AccordionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const AccordionTitle = styled.p`
    font-size:18px;
    color: #161616;
    font-weight: 600;
    margin:0;
    cursor: pointer;
`;

export const AccordionIcon = styled.img`
    height:10px;
    cursor: pointer;
`;

export const AccordionSubSection = styled.div`

`;

export const AccordionSubTitle = styled.p`
    font-size: 16px;
    color: ${(props) => (props.active ? '#42A764' : '#161616')};
    font-weight: ${(props) => (props.active ? '600' : '400')};
    cursor: pointer;
    &:hover {
         color: #55ff8e !important;
        }
`;

export const HeaderContainer = styled.div`
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
`;

export const HeaderText = styled.h2`
   
`;

export const SearchInput = styled.input`
    background: url('${eyeglass}') white;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 8px 8px 8px 30px; /* Adjust the padding as needed */
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    transition: border 0.15s linear, box-shadow 0.15s linear;
`;

export const LibraryContainerBody = styled(Row)`
    display: flex;
    justify-content: space-between;
    margin: 0px !important;
    padding: 0;
`;

export const DetailsSection = styled(Col)`
    max-height: 80vh;
    overflow-y: auto;
    @media (max-width: 991px) {
        padding:0 !important;
        max-height: unset !important;
    }
        .fade-enter {
        opacity: 0;
        transform: scale(0.95);
        }
        .fade-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 300ms, transform 300ms;
        }
        .fade-exit {
        opacity: 1;
        transform: scale(1);
        }
        .fade-exit-active {
        opacity: 0;
        transform: scale(0.95);
        transition: opacity 300ms, transform 300ms;
        }
`;

export const PageSection = styled.div`
    border-radius: 6px;
    background-color:#ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border: 1px solid  #b9b7b7!important;
    padding: 15px;
    margin-left: 10px;
    @media (max-width: 991px) {
        margin:0 !important;
    }
`;

export const PageHeader = styled.p`
    font-size:20px;
    color: #161616;
    font-weight: 600;
`;

export const PageContent = styled.div`
    color: #161616;
    font-size:15px;
`;

export const PageFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PageFooterNavigation = styled(Col)`
    p {
        color: #42A764;
        font-size: 16px !important;
        font-weight: 600 !important;
        margin-bottom:0;
        cursor: pointer;
    }
`;