import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

import vectorIcon from '../../assets/images/Vector.svg';
import smallLogo from '../../assets/images/smallLogo.svg';
import bigLogo from '../../assets/images/bigLogo.svg';
import sortIcon from '../../assets/images/sort-icon.svg';
import ascendingSortIcon from '../../assets/images/descending-sort-icon.svg';
import descendingSortIcon from '../../assets/images/ascending-sort-icon.svg';
import applyFilterGray from '../../assets/images/filter-apply.svg';
import applyFilterGreen from '../../assets/images/filter-active.svg';
import logo from '../../assets/images/logo.svg';

import { Button, Input } from '../../components';
import { ButtonContainer, FilterContainer, OverlayContainer, PaginationSection, TableContainer, TableHead } from '../Administration/Style';

import RequestToSDS from './RequestToSDS';
import { loadUser, loadUserSuccess } from '../../actions';
import ViewSDS from './ViewSDS';
import MultiTextInput from '../../components/MultiTextInput';

const SDSAddContainer = styled.div`
    width: 100%;
    height: 100%;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius:10px;
    @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 6.5rem 0.5rem 0rem 6rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:6.5rem 0.5rem 0rem 6rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

const Heading = styled.h2`
    padding:0.875rem;
    margin:0;
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const SmallImage = styled.div`
    padding: 0 !important;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin:25px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  border-radius: 7px;
  background: #fff;

  p {
    margin: 0 50px 0 0;
    color: #000;
    font-size: 14px;
  }


     @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 3rem !important;
        max-width: 100%;
        display: flex;
        padding: 0;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 3rem !important;
        max-width: 100%;
        display: flex;
        padding: 0;
        
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:1.5rem 0.5rem 1.5rem 0.5rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        min-width: -webkit-fill-available;
        
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin:1.5rem 0.5rem 1.5rem 0.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

const Frame = styled.div`
  align-items: center;
  padding: 0 !important;
  float: left;
  position: inherit !important;

  @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:1.5rem 0.5rem 0rem 0.5rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
        min-width: inherit;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin:1.5rem 0.5rem 0rem 0.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

const BannerImageStyle = styled.div`
  align-items: center;
  padding: 20px 20px 0px 20px !important;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  border-radius: 7px;
  background: #fff;

  @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 4rem 0rem 0rem 0rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:1.5rem 0.5rem 0rem 0.5rem !important;
        display: block;
        padding: 0;
        overflow: auto;
        min-width: inherit;
        display: grid
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin:1.5rem 0.5rem 0rem 0.5rem !important;
        display: block;
        padding: 0;
        overflow: auto;
        min-width: inherit;
        display: grid
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

const BannerRightSideImage = styled.div`
  padding-right: 0px !important;
  text-align: end;

  @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: -5.5rem 0rem 0rem 0rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:1.5rem 0rem 0rem 0rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
        min-width: inherit;

    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin:1.5rem 0rem 0rem 0rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
 
`;

const SubHeaderSection = styled.div`
    padding-top:10px;
    border-bottom: 1px groove;
`;

const SubHeader = styled.h6`
    font-weight: 600 !important;
`;

const SDSHeading = styled.div`
    display: flex;

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        display: flow;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        display: flow;
    }
 `;

class SDSAdd extends Component {

    state = {
        loading: false,
        sdsList: [],
        sdsDetails: { name: '' },
        orderBy: 'nameForSort',
        order: 'asc',
        pageSize: 10,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        errors: {},
        saveOrUpdateInProgress: false,
        openRequestToSDSModal: false,
        filterDetails: {},
        activeFilterDetails: {},
        filterColumns: {},
        viewSdsDocOpen: false,
        availableSDS: []
    };

    componentDidMount() {
        this.getSDS();
    };
    componentWillReceiveProps = (updatedProps) => {
        this.setState({ availableSDS: updatedProps.user.location.sdsList })
    };
    getSDS = async () => {
        try {
            const { user, dispatch } = this.props;
            await dispatch(loadUser(user));
            const { pageSize, pageNumber, orderBy, order, activeFilterDetails } = this.state;
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(`/api/v2/sdsList/${user.location._id}`, { params: { ...activeFilterDetails, pageSize, pageNumber, orderBy, order, allSheets: true } });
            const pageCount = Math.ceil(payload.totalCount / pageSize);
            this.setState({ loading: false, sdsList: payload.data, totalCount: payload.totalCount, pageCount });
        } catch (error) {
            this.setState({ loading: false, errors: { getUsersErr: true, errorMsg: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
        };
    };

    sort = (orderBy, order) => {
        this.setState({ order, orderBy, pageNumber: 1, pageSize: 10 }, () => {
            this.getSDS();
        });
    };

    handlePageClick = (val) => {
        this.setState({ pageNumber: val.selected + 1 }, () => {
            this.getSDS();
        });
    };

    onFilterInputChange = (name, value) => {
        const { filterDetails } = this.state;
        filterDetails[name] = value;
        this.setState({ filterDetails });
    };

    applyFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;
        if (colName === 'trainingDueDate') {
            activeFilterDetails[colName] = moment(filterDetails[colName]).subtract(1, 'year');
        } else {
            activeFilterDetails[colName] = filterDetails[colName];
        };
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getSDS();
        });
    };

    clearFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;

        delete filterDetails[colName];
        delete activeFilterDetails[colName];
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getSDS();
        });
    };

    openCloseFilterSection = (colName) => {
        const { filterColumns } = this.state;
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ filterColumns });
    };

    requestToSDS = () => {
        this.setState({ openRequestToSDSModal: true });
    };

    closePopUpModalDialog = (getLocations) => {
        this.setState({ openRequestToSDSModal: false })
        if (getLocations) {
            this.getSDS();
        };
    };

    viewSDSDoc = (sdsDetails) => {
        this.setState({ viewSdsDocOpen: true, sdsDetails });
    };

    addRemoveSDS = async (action, sdsId) => {
        try {
            const { user, dispatch } = this.props;
            const { data: { payload } } = await axios.post(`/api/v2/add_remove_sds`, { action, sdsId, locationId: user.location._id });
            user.location = payload;
            dispatch(loadUserSuccess(user));
            this.setState({ availableSDS: payload.sdsList });
        } catch (error) {
            console.log("🚀 ~ file: AddSDS.jsx:450 ~ SDSAdd ~ addRemovedSDS=async ~ error:", error);
        }
    };

    render() {
        const { sdsList, errors, openRequestToSDSModal, loading, pageNumber, pageCount, orderBy, order, filterDetails, filterColumns, activeFilterDetails, viewSdsDocOpen, sdsDetails, availableSDS } = this.state;

        const { user, history } = this.props;
        return (
            <SDSAddContainer>
                <SDSHeading className="border-bottom justify-content-between mx-2 mt-2">
                    <Heading>Safety Data Sheets Library</Heading>
                    <Heading>
                        <Button width='150' className='my-0' type='button' onClick={() => history.goBack()}>{'<< Back'}</Button>
                    </Heading>
                </SDSHeading>

                <BannerContainer className="row">
                    <SmallImage className="col-1">
                        <img src={bigLogo} alt="logo" />
                    </SmallImage>
                    <Frame className="col-4">
                        <h3><b>Don’t see the SDS you need?</b></h3>
                        <h4>Send us a request and we’ll add it to our library</h4>

                        <Button width='150' onClick={() => this.requestToSDS()} type='button' className='m-2' ><img src={vectorIcon} alt="Vector Icon" className='mr-2' />Request an SDS</Button>
                        <br></br><Link className='m-4' to='/sds/show-requests'>Show Requests</Link>
                    </Frame>
                    <BannerImageStyle className="col-4">
                        <Row>
                            <Col>
                                <h5><b>Safety Data Sheet</b></h5>
                            </Col>
                            <Col style={{ textAlignLast: 'right' }}>
                                <img src={logo} alt="logo1" />
                            </Col>
                        </Row>
                        <p>according to Regulation(EC) No. 1907/2006 (REACH)</p>
                        <p>according to Regulation(EU) No. 2020/878 (REACH)</p>

                        <SubHeaderSection>
                            <SubHeader>Chemical Details</SubHeader>
                        </SubHeaderSection>
                        <div>
                            <table className='table mb-0'>
                                <TableHead>
                                    <tr>
                                        <th>
                                            Chemical Name
                                        </th>
                                        <th>
                                            Manufacturer
                                        </th>
                                        <th>
                                            Subsidiary Ingredient CAS
                                        </th>
                                        <th>
                                            Identified uses
                                        </th>
                                    </tr>
                                </TableHead>
                            </table>
                        </div>
                    </BannerImageStyle>
                    <BannerRightSideImage className='col-3'>
                        <img src={smallLogo} alt="logo2" />
                    </BannerRightSideImage>
                </BannerContainer>
                <TableContainer maxHeight='70vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className='w-25 pl-5'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.chemicalName && !activeFilterDetails.chemicalName) && <img onClick={() => this.openCloseFilterSection('chemicalName')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.chemicalName || activeFilterDetails.chemicalName) && <img onClick={() => this.openCloseFilterSection('chemicalName')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.chemicalName &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.chemicalName || ''} onChange={(e) => this.onFilterInputChange('chemicalName', e.target.value)} placeholder='Chemical Name' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('chemicalName')}>Clear</button>
                                                    <button disabled={!filterDetails.chemicalName} className='btn btn-success' type='button' onClick={() => this.applyFilter('chemicalName')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Chemical Name
                                    <span>
                                        {(orderBy !== 'lowerChemicalName') && <img onClick={() => this.sort('lowerChemicalName', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'lowerChemicalName' && order === 'asc') && <img onClick={() => this.sort('lowerChemicalName', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'lowerChemicalName' && order === 'desc') && <img onClick={() => this.sort('lowerChemicalName', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-25'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.manufacturer && !activeFilterDetails.manufacturer) && <img onClick={() => this.openCloseFilterSection('manufacturer')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.manufacturer || activeFilterDetails.manufacturer) && <img onClick={() => this.openCloseFilterSection('manufacturer')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.manufacturer &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.manufacturer || ''} onChange={(e) => this.onFilterInputChange('manufacturer', e.target.value)} placeholder='Manufacturer' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('manufacturer')}>Clear</button>
                                                    <button disabled={!filterDetails.manufacturer} className='btn btn-success' type='button' onClick={() => this.applyFilter('manufacturer')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Manufacturer
                                    <span>
                                        {(orderBy !== 'lowerManufacturer') && <img onClick={() => this.sort('lowerManufacturer', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'lowerManufacturer' && order === 'asc') && <img onClick={() => this.sort('lowerManufacturer', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'lowerManufacturer' && order === 'desc') && <img onClick={() => this.sort('lowerManufacturer', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-25'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.cas && !activeFilterDetails.cas) && <img onClick={() => this.openCloseFilterSection('cas')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.cas || activeFilterDetails.cas) && <img onClick={() => this.openCloseFilterSection('cas')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.cas &&
                                            <OverlayContainer>
                                                <MultiTextInput
                                                    name='cas'
                                                    data={filterDetails.cas && filterDetails.cas.map((task) => {
                                                        return { label: task, value: task };
                                                    })}
                                                    onChange={(e) => this.onFilterInputChange('cas', e.map(t => t.value))}
                                                    placeholder='Subsidiary Ingredient CAS'
                                                />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('cas')}>Clear</button>
                                                    <button disabled={!filterDetails.cas} className='btn btn-success' type='button' onClick={() => this.applyFilter('cas')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Subsidiary Ingredient CAS <span>
                                        {(orderBy !== 'cas') && <img onClick={() => this.sort('cas', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'cas' && order === 'asc') && <img onClick={() => this.sort('cas', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'cas' && order === 'desc') && <img onClick={() => this.sort('cas', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-25'>Actions</th>
                            </tr>
                        </TableHead>
                        {(!loading && sdsList.length > 0) &&
                            <tbody>
                                {sdsList.map((sds, index) => (
                                    <tr key={index}>
                                        <td className='w-25 pl-5'>{sds.chemicalName}</td>
                                        <td className='w-25'>{sds.lowerManufacturer}</td>
                                        <td className='w-25'>{sds.cas && sds.cas.length ? sds.cas.toString() : '- -'}</td>
                                        <td className='w-25'>
                                            <i onClick={() => this.viewSDSDoc(sds)} className='fa fa-eye text-primary cursor-pointer' />
                                            {availableSDS && availableSDS.includes(sds._id) ?
                                                <span onClick={(e) => this.addRemoveSDS('remove', sds._id)} className='text-danger ml-4 cursor-pointer'> <i className='fa fa-minus' /> Remove from library</span>
                                                : <span onClick={(e) => this.addRemoveSDS('add', sds._id)} className='text-success ml-4 cursor-pointer'> <i className='fa fa-plus' /> Add to library</span>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                    {(!sdsList.length && !loading) &&
                        <Heading className='d-flex justify-content-center'>
                            {Object.values(activeFilterDetails).length ? "No results match your search criteria" : "No Safety Data Sheets added yet."}
                        </Heading>
                    }
                    {
                        loading &&
                        <div className='w-100 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                    }
                </TableContainer>

                {errors.getUsersErr &&
                    <Row className='m-0'>
                        <Col>
                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                <p className='w-100 mb-0'>{errors.errorMsg}</p>
                            </Alert>
                        </Col>
                    </Row>
                }
                <PaginationSection>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        previousLabel="<"
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                    />
                </PaginationSection>

                {openRequestToSDSModal &&
                    <RequestToSDS show={openRequestToSDSModal} user={user}
                        closePopUpModal={this.closePopUpModalDialog} />
                }
                {viewSdsDocOpen && <ViewSDS show={viewSdsDocOpen} sdsDetails={sdsDetails} handleClose={() => this.setState({ viewSdsDocOpen: false })} />}
            </SDSAddContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(SDSAdd);