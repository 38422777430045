import { useState } from "react";
import axios from "axios";
import { Alert, Button, Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import styled from "styled-components"

import pencil from '../../assets/images/pencil-green.svg';
import CloseIcon from '../../assets/images/glyph-close-gray.svg';
import SaveIcon from '../../assets/images/glyph-checkmark-green.svg';
import CloseCircularIcon from '../../assets/images/button-close-mini.svg';
import AddCircularIcon from '../../assets/images/button-add-mini.svg';
import infoGreen from '../../assets/images/info-green.svg';
import { DropDownStyle as DropDownStyleDefault, isMongoObjectId } from '../../helpers';

const SingleAnswerBlock = styled.span`
    display: inline-flex;
    justify-content: space-between;
    min-width: 10vw;
    padding: 8px 16px;
    align-items: center;
    border-radius: 100px;
    background: #F9F9F9;
`;

const TableAnswerBlock = styled.div`
    table{
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
        border: 1px solid  #d9d9d9!important;
        border-radius: 20px;
        tr:nth-child(even) { 
            background-color: #F9F9F9; 
        } 
    }
`;

const MultiInputField = styled(Form.Control)`
    padding: 20px !important; /* Adjust the padding as needed */
    font-size: 14px !important;
    font-family: 'Hauora' !important;
    color: var(--dark-text-color) !important;
    font-weight: 600 !important;
    border-width: 0.5px 0px 0.5px 0.5px !important;
    border-style: solid !important;
    border-color: #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 100px 0px 0px 100px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;

    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        // background-color: #e2e6ea !important;
        border-color: #4fb973 !important;
    };
`;

const SingleInputField = styled(Form.Control)`
    padding: 20px !important; /* Adjust the padding as needed */
    font-size: 14px !important;
    font-family: 'Hauora' !important;
    color: var(--dark-text-color) !important;
    font-weight: 600 !important;
    border: 0.5px solid #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 100px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;
    
    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: #4fb973 !important;
    };
`;

const TableInputField = styled.input`
    padding: 8px !important; /* Adjust the padding as needed */
    font-size: 14px !important;
    font-family: 'Hauora' !important;
    color: var(--dark-text-color) !important;
    font-weight: 600 !important;
    border: 0.5px solid #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 5px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;
    width: 100%;

    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: #4fb973 !important;
    };
`;

const RemoveButton = styled(Button)`
    background-color:#FFFFFF !important;
    font-family: 'Hauora' !important;
    border: 0.5px solid #b9bcca !important;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 0px 100px 100px 0px !important;
    transition: border 0.15s linear, box-shadow 0.15s linear !important;

    &:disabled {
        cursor: not-allowed !important;
    };
    
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        background-color: #e2e6ea !important;
        border-color: #4fb973 !important;
    };
`;

const AddButton = styled.button`
    width: 100%;
    padding: 5px 16px;
    align-items: center;
    border-radius: ${(props) => (props.table ? '5px' : '100px')};
    border: none;
    background: #f9f9f961;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    &:hover {
        background-color: #e2e6ea;
    }
`;

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

const UserSelectContainer = styled.div`
width: 25vw;
.min-25vw {
    min-width: 25vw !important;
}
`;

const updateLibraryData = async (libraryData) => {
    try {
        const { data } = await axios.put(`/api/v1/updateLibraryData`, libraryData);

        return data;
    } catch (error) {
        throw error;
    }
};

const DropDownStyle = {
    option: (provided, { isDisabled, isSelected, isFocused }) => ({
        ...provided,
        backgroundColor: isDisabled ? null : isFocused ? '#4fb973' : null,
        color: isFocused ? 'white' : 'black'
    }),
    control: (provided) => ({
        ...provided,
        'boxShadow': 'inset 0 1px 1px 0 rgba(0, 0, 0, 0.16)',
        'borderColor': '#b9bcca',
        'borderRadius': '100px',
        'padding': '2px',
        ':hover': {
            'borderColor': '#4fb973'
        },
        ':active': {
            'borderColor': '#4fb973'
        }
    })
};

export const SingleAnswerCodeView = ({ answerData, user, getLibData }) => {

    const [answer, setAnswer] = useState(answerData.answer);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.inputType,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer || !answer.trim()) return setError('Required');
            setError('');
            setLoading(true);
            await updateLibraryData({ libraryData, locationId: user.location._id });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setLoading(false);
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
        }
    };

    return (
        <>
            {edit ?
                <Row style={{ width: "35vw", alignItems: 'center' }} className="d-inline-flex justify-content-between mx-2 mt-2">
                    <Col sm='12' lg='6'>
                        {/* <UserSelect selectedUser={''} usersList={usersList} /> */}
                        <SingleInputField disabled={loading} type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} />
                    </Col>
                    <Col sm='12' lg='6'>
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Col>
                    {error && <Col sm='12' lg='12' md='12'> <Alert variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert></Col>}
                </Row>
                :
                <SingleAnswerBlock>
                    <strong>{answerData.answer}</strong>
                    {!answerData.editOff &&
                        <img onClick={() => { setEdit(true); setAnswer(answerData.answer) }} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                    }
                </SingleAnswerBlock>
            }
        </>
    )
};

export const MultiUserSelect = ({ answerData, user, getLibData, usersList }) => {
    const [answer, setAnswer] = useState(answerData.answer);
    const [users, setUsers] = useState(usersList);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.inputType,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer) return setError('Required');
            if (!answer.length) return setError('Required');
            let tempError = false;
            answer.map(ans => {
                if (ans && ans.trim()) {
                    return ans.trim();
                } else {
                    setError('Fill all fields');
                    tempError = true;
                    return ans;
                };
            });
            if (tempError) return;
            setError('');
            if (JSON.stringify(answerData.answer) === JSON.stringify(answer)) return setEdit(false);

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: user.location._id });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onInputChange = (e) => {

        if (e.target.value.trim() && e.key === 'Enter') {
            const check = users.some(u => u.value === e.target.value.trim());
            if (!check) {
                e.preventDefault();
                setUsers([...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }]);
                setAnswer([...answer, e.target.value.trim()]);
            };
            e.target.value = '';
        };
    };

    const onMultiInputChange = (value) => {
        setAnswer(value);
    };

    const onEdit = () => {
        const otherOptions = users.filter(u => !u.other) || [];
        answer.forEach(op => {
            !isMongoObjectId(op) && otherOptions.push({ label: op, value: op, other: true });
        });
        setUsers(otherOptions);
        setEdit(true);
    };

    const getAnswer = () => answer.map(op => isMongoObjectId(op) ? users.find(u => u.value === op)?.label : op);

    return (
        <>
            {edit ?
                <UserSelectContainer className="mx-2">
                    <div className="d-flex justify-content-end my-2">
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                    <div >
                        <div className='d-flex'>
                            <Select
                                name="multiUserSelect"
                                value={users.filter(u => answer.includes(u.value))}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                options={users}
                                onKeyDown={onInputChange}
                                isMulti={true}
                                className='mx-1 min-25vw'
                                onChange={(e) => onMultiInputChange(e.map(u => u.value))}
                            />
                            <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                                    </Tooltip>
                                }>
                                <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                            </OverlayTrigger>
                        </div>
                        {error && <Alert className="mt-2" variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}
                    </div>
                </UserSelectContainer>
                :
                <SingleAnswerBlock>
                    <strong>{getAnswer().toString()}</strong>
                    <img onClick={() => onEdit()} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                </SingleAnswerBlock>
            }
        </>
    )
};

export const SingleUserSelect = ({ answerData, user, getLibData, usersList }) => {
    const [answer, setAnswer] = useState(answerData.answer);
    const [users, setUsers] = useState(usersList);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.inputType,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer) return setError('Required');
            if (!answer.length) return setError('Required');

            setError('');
            if (answerData.answer === answer) return setEdit(false);

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: user.location._id });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onInputChange = (e) => {

        if (e.target.value.trim() && e.key === 'Enter') {
            const check = users.some(u => u.value === e.target.value.trim());
            if (!check) {
                e.preventDefault();
                setUsers([...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }]);
                setAnswer(e.target.value.trim());
            };
            e.target.value = '';
        };
    };

    const onSingleInputChange = (value) => {
        setAnswer(value);
    };

    const onEdit = () => {
        const otherOptions = users.filter(u => !u.other) || [];
        !isMongoObjectId(answer) && otherOptions.push({ label: answer, value: answer, other: true });
        setUsers(otherOptions);
        setEdit(true);
    };

    const getAnswer = () => isMongoObjectId(answer) ? users.find(u => u.value === answer)?.label : answer;

    return (
        <>
            {edit ?
                <UserSelectContainer className="mx-2">
                    <div className="d-flex justify-content-end my-2">
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                    <div >
                        <div className='d-flex'>
                            <Select
                                name="singleUserSelect"
                                value={users.filter(u => u.value === answer)}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                options={users}
                                onKeyDown={onInputChange}
                                isMulti={false}
                                className='mx-1 min-25vw'
                                onChange={(e) => onSingleInputChange(e.value)}
                            />
                            <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                                    </Tooltip>
                                }>
                                <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                            </OverlayTrigger>
                        </div>
                        {error && <Alert className="mt-2" variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}
                    </div>
                </UserSelectContainer>
                :
                <SingleAnswerBlock>
                    <strong>{getAnswer().toString()}</strong>
                    <img onClick={() => onEdit()} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                </SingleAnswerBlock>
            }
        </>
    )
};

export const MultiAnswerCodeView = ({ answerData, user, getLibData }) => {
    const [answer, setAnswer] = useState(answerData.answer);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        try {
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.inputType,
                stepNumber: answerData.stepNumber,
                question: answerData.question
            };
            if (!answer) return setError('Required');
            let tempError = false;
            answer.map(ans => {
                if (ans && ans.trim()) {
                    return ans.trim();
                } else {
                    setError('Fill all fields');
                    tempError = true;
                    return ans;
                };
            });
            if (tempError) return;
            setError('');
            if (JSON.stringify(answerData.answer) === JSON.stringify(answer)) return setEdit(false);;

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: user.location._id });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onChange = (index, value) => {
        const tempAnswer = [...answer];
        tempAnswer[index] = value;
        setAnswer(tempAnswer);
    };

    const addRow = () => {
        const tempAnswer = [...answer];
        tempAnswer.push("");
        setAnswer(tempAnswer);
    };

    const removeRow = (e, index) => {
        const tempAnswer = [...answer];
        tempAnswer.splice(index, 1);
        setAnswer(tempAnswer);
    };

    return (
        <>
            {edit ?
                <div style={{ width: '25vw' }} className="mx-2">
                    <div className="d-flex justify-content-end my-2">
                        <Button disabled={loading} onClick={() => setEdit(false)} variant="light" className="text-secondary mx-1">
                            <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                        </Button>
                        <Button disabled={loading} onClick={saveAnswer} variant="light" className="text-center text-success mx-1">
                            <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                    <div style={{ width: '25vw' }}>
                        {answer.map((ans, index) =>
                            <InputGroup key={index} className="d-flex mt-2">
                                <MultiInputField disabled={loading} type="text" value={ans} onChange={(e) => onChange(index, e.target.value)} />
                                <RemoveButton disabled={answer.length < 2 || loading} variant="light" onClick={(e) => removeRow(e, index)}> <img src={CloseCircularIcon} alt="" /></RemoveButton>
                            </InputGroup>
                        )}

                        {error && <Alert variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}

                        <AddButton disabled={loading} onClick={addRow} className="mt-3"><img src={AddCircularIcon} alt="" className="cursor-pointer mr-1" /> Add row</AddButton>
                    </div>
                </div>
                :
                <SingleAnswerBlock>
                    <strong>{answerData.answer.toString()}</strong>
                    <img onClick={() => { setEdit(true); setAnswer(answerData.answer) }} className="cursor-pointer ml-2" src={pencil} alt="Edit" />
                </SingleAnswerBlock>
            }
        </>
    )
};

export const TableAnswerCodeView = ({ answerData, headers, getLibData, user, usersList }) => {

    const [answer, setAnswer] = useState(JSON.parse(JSON.stringify(answerData.answer)));
    const [edit, setEdit] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const saveAnswer = async (e) => {
        e.preventDefault();
        try {
            const form = e.currentTarget;
            if (!form.checkValidity()) {
                e.preventDefault();
                e.stopPropagation();
                setValidated(true);
                return
            };
            const libraryData = {
                answer,
                associatedVariable: answerData.associatedVariable,
                type: answerData.inputType,
                stepNumber: answerData.stepNumber,
                question: answerData.question,
                isTaskForThisStep: answerData.isTaskForThisStep,
                taskDetails: answerData.isTaskForThisStep ? answerData.taskDetails : {},
                tableLayout: answerData.tableLayout || {}
            };
            setError('');
            if (JSON.stringify(answerData.answer) === JSON.stringify(answer)) return setEdit(false);;

            setLoading(true);
            await updateLibraryData({ libraryData, locationId: user.location._id });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Data has been updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(async () => {
                await getLibData();
                setEdit(false);
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const onChange = (index, header, value) => {
        const tempAnswer = [...answer];
        tempAnswer[index][header] = value;
        setAnswer(tempAnswer);
    };

    const addRow = () => {
        const tempAnswer = [...answer];
        tempAnswer.push({});
        setAnswer(tempAnswer);
    };

    const removeRow = (e, index) => {
        const tempAnswer = [...answer];
        tempAnswer.splice(index, 1);
        setAnswer(tempAnswer);
    };

    return (
        <>
            {edit ?
                <TableAnswerBlock>
                    <Form noValidate validated={validated} onSubmit={saveAnswer}>
                        <div className="text-right mb-2">
                            <Button disabled={loading} onClick={() => { setEdit(false); setError(''); setValidated(false) }} variant="light" className="text-secondary mx-1">
                                <img src={CloseIcon} alt="" className="mr-2" /> Cancel
                            </Button>
                            <Button disabled={loading} type="submit" variant="light" className="text-center text-success mx-1">
                                <img src={SaveIcon} alt="" className="mr-2" /> {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                        <Table striped responsive="md">
                            <thead>
                                <tr>
                                    {headers.map((header, a) => <th key={a + 'tableHead'}>{header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {answer.map((ans, b) =>
                                    <tr key={b + 'tableRow'}>
                                        {headers.map((header, c) =>
                                            <td key={c + b + 'tableData'}>
                                                {
                                                    answerData.tableLayout.userSelectColumns && answerData.tableLayout.userSelectColumns.includes(header) ?
                                                        <div>
                                                            <input type="text" value={ans[header] || ''} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }} />
                                                            <Select
                                                                name={header}
                                                                value={usersList.find(u => u.value === ans[header]) || null}
                                                                menuPosition="fixed"
                                                                styles={DropDownStyleDefault}
                                                                options={usersList}
                                                                onChange={(e) => onChange(b, header, e.value)}
                                                            />
                                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                                        </div>
                                                        :
                                                        <div>
                                                            <TableInputField required={true} name={header + (c + b)} onChange={(e) => onChange(b, header, e.target.value)} value={ans[header]} />
                                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                                        </div>
                                                }

                                            </td>
                                        )}
                                        <td style={{ width: '5%' }}>
                                            <Button type="button" disabled={answer.length < 2 || loading} variant="light" onClick={(e) => removeRow(e, b)}>
                                                <img src={CloseCircularIcon} alt="" />
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={headers.length + 1}>
                                        {error &&
                                            <Alert variant="danger" style={{ maxWidth: '100%' }} >
                                                {error}
                                            </Alert>
                                        }
                                        <AddButton type="button" table={true} disabled={loading} onClick={addRow} >
                                            <img src={AddCircularIcon} alt="" className="cursor-pointer mr-1" /> Add row
                                        </AddButton>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Form>
                </TableAnswerBlock>
                :
                <TableAnswerBlock>
                    <div className="text-right">
                        <strong onClick={() => { setAnswer(JSON.parse(JSON.stringify(answerData.answer))); setEdit(true) }} className="text-success cursor-pointer">
                            <img src={pencil} alt="Edit" /> Edit
                        </strong>
                    </div>
                    <Table striped responsive="md">
                        <thead>
                            <tr>
                                {
                                    headers.map((header, a) =>
                                        <th key={a + 'tableHead'}>
                                            {header}
                                        </th>
                                    )}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                answerData.answer.map((ans, b) =>
                                    <tr key={b + 'tableRow'}>
                                        {headers.map((header, c) =>
                                            <td key={c + b + 'tableData'}>
                                                {
                                                    answerData.tableLayout.userSelectColumns && answerData.tableLayout.userSelectColumns.includes(header) ? usersList.find(u => u.value === ans[header])?.label || '--' : ans[header]
                                                }
                                            </td>
                                        )}
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </TableAnswerBlock>
            }
        </>
    )
};